import React from "react";
import styled from "styled-components/macro";
import { FlexColumn, MarketTextBlack } from "../../style/projectComponents";
import { useTranslation } from "react-i18next";
import { marketList } from "../../config/marketList";
import { TradingPair } from "./TradingPair";
import { ITicker } from "../../service/bybit-api/models";
import { TabType } from "../../pages/MarketsPage/MarketsPage";

const Block = styled(FlexColumn)<{ $tab: TabType }>`
  width: 25vw;
  justify-content: start;
  max-width: 300px;
  height: 100%;
  max-height: 676px;
  overflow: scroll;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;

  p {
    font-size: 15px;
    margin: 0;
    text-align: start;
    width: 100%;
  }
  @media (max-width: 600px) {
    display: ${(props) => (props.$tab === "trading" ? "block" : "none")};
    width: 100vw;
    max-width: 100vw;
  }
`;
const BlockHeader = styled(FlexColumn)`
  justify-content: start;
  width: 100%;
  padding: 10px 0;
`;

interface TradingPairsListProps {
  tradingPair: string;
  tickerList: ITicker[] | null;
  tab: TabType;
}
export const TradingPairsList: React.FC<TradingPairsListProps> = ({
  tradingPair,
  tickerList,
  tab,
}) => {
  const { t } = useTranslation();

  return (
    <Block $tab={tab}>
      <BlockHeader>
        <MarketTextBlack style={{ marginLeft: 15 }}>
          {t("menu.markets")}
        </MarketTextBlack>
      </BlockHeader>
      {marketList.map((token, id) => (
        <TradingPair
          tickerList={tickerList}
          activePair={tradingPair}
          link={token.link}
          key={token.pair}
        />
      ))}
    </Block>
  );
};
