import React, {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  useState,
} from "react";
import * as Def from "../../style/projectComponents";
import * as Time from "../../style/projectTimeComponents";
import styled from "styled-components/macro";
import { PROJECT_NAME } from "../../config/serverConstants";

const StyledTextArea =
  PROJECT_NAME === "Def" ? Def.StyledTextArea : Time.StyledTextArea;

type ValidationInputComponentProps = {
  value?: string;
  isError?: any;
  label?: string;
  ref?: any;
};

export const ValidationLabel = styled.label<{ isFocused?: any }>`
  display: ${({ isFocused }) => (isFocused ? "block" : "none")};
  font-size: 14px;
  position: absolute;
  left: 20px;
  margin-top: 10px;
  transition: all 1s;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 480px) {
    left: 70px;
  }
`;

export const CustomStyledTextArea = styled(StyledTextArea)`
  width: 100%;
  position: relative;
  font-family: sans-serif;

  @media (max-width: 480px) {
  }
` as React.FC<ComponentPropsWithoutRef<"textarea">>;
export const ValidationTextAreaComponent: React.FC<
  ValidationInputComponentProps & ComponentPropsWithoutRef<"input">
> = (props) => {
  const { value, isError, label, ...other } = props;
  const [isFocused, setIsFocused] = useState(false);
  return (
    <CustomStyledTextArea
      {...other}
      value={value ? value : ""}
      placeholder={label}
      onChange={
        other.onChange as unknown as ChangeEventHandler<HTMLTextAreaElement>
      }
      // @ts-ignore
      isFailed={isError}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    />
  );
};
