import React from "react";
import styled from "styled-components/macro";
import { FlexRow } from "../../style/projectComponents";
import { generatePagesArray } from "../../utils/generatePagesArray";
import { Spacer } from "./Spacer";
import { PROJECT_NAME } from "../../config/serverConstants";

const PageWrapper = styled(FlexRow)`
  width: 100vw;
  @media (min-width: 768px) {
    align-self: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
  }
`;

interface PageButtonProps {
  isSelected: boolean;
}

const PageButton = styled.button<PageButtonProps>`
  margin: 6px;
  margin-top: 20px;
  background: ${({ isSelected, theme }) =>
    isSelected
      ? `${
          PROJECT_NAME === "Def"
            ? "linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%)"
            : "#22B8B6"
        }`
      : theme.colors.gray050};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.white : theme.colors.black};
  min-width: 32px;
  width: auto;
  height: 32px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }
`;

interface PaginationComponentProps {
  maxPages: number;
  pageId: number;
  pagesNum?: number[];
  setPageId: (page: number) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  maxPages,
  pageId,
  pagesNum,
  setPageId,
}) => {
  if (!pagesNum) {
    pagesNum = generatePagesArray(maxPages, pageId, 100);
  }

  if (pagesNum?.length <= 1) {
    return <Spacer />;
  }

  if (pagesNum?.length > 1) {
    return (
      <PageWrapper>
        {pageId !== 0 && (
          <PageButton
            onClick={() => {
              setPageId(pageId - 1);
            }}
            isSelected={pageId === 0}
          >
            <img
              src="/images/buttons/next.svg"
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </PageButton>
        )}

        {pagesNum
          .filter(
            (page, idx) =>
              Math.abs(page - pageId) <=
              (pageId < 2 || pageId > maxPages - 2 ? 3 : 2)
          )
          .map((page, id) => (
            <PageButton
              isSelected={page === pageId + 1}
              onClick={() => {
                setPageId(page - 1);
              }}
              key={id}
            >
              {page}
            </PageButton>
          ))}

        {pageId < maxPages - 1 && (
          <PageButton
            onClick={() => {
              setPageId(pageId + 1);
            }}
            isSelected={pageId === maxPages}
          >
            <img src="/images/buttons/next.svg" alt="" />
          </PageButton>
        )}
      </PageWrapper>
    );
  }
};
