import React, {useContext, useEffect} from "react";
import {
    EditModalWrapper,
    FlexRow,
    Modal,
    ModalHeader,
    StyledButton,
} from "../../../style/projectComponents";
import styled from "styled-components/macro";
import {Controller, useForm} from "react-hook-form";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {useMutation} from "react-query";
import {ChangeExchangeCommissionReq} from "../../../service/admin-api/models";
import AdminApi from "../../../service/admin-api/AdminApi";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import {useTranslation} from "react-i18next";

const ModalWrapper = styled(Modal)`
  background: ${({theme}) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 20px;
  margin: 10px;
  gap: 12px;
  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
`;

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

type IFormType = {
    name: "newExhangeComission";
    label: string;
    rules: any;
    type: string;
};

const forms: IFormType[] = [
    {
        name: "newExhangeComission",
        label: "New exchange commission",
        rules: {
            required: true,
            validate: {
                // Проверка на то, что число больше или равно 0
                positive: (value) => value >= 0 || "Value must be 0 or greater.",
                // Проверка на то, что число меньше или равно 100
                max: (value) => value <= 100 || "Value must be 100 or less."
            }
        },
        type: "number",
    },
];

interface EditWalletModalProps {
    openMenu: boolean;
    handleMenu: () => void;
    currentCommission: number;
    mutate: any;
    mutateData: any;
}

const EditCommissionModal: React.FC<EditWalletModalProps> = ({
                                                                 openMenu,
                                                                 handleMenu,
                                                                 currentCommission,
                                                                 mutate,
                                                                 mutateData
                                                             }) => {
    const {
        control,
        setValue,
        formState: {errors, isValid, isDirty},
        handleSubmit,
    } = useForm({
        defaultValues: {
            newExhangeComission: currentCommission?.toString(),
        },
    });

    const changeCommission = useMutation((data: ChangeExchangeCommissionReq) =>
        AdminApi.changeExchangeCommission(data)
    );
    const GlobalModalStorage = useContext(GlobalModalStore);
    const {t} = useTranslation();

    useEffect(() => {
        setValue("newExhangeComission", currentCommission?.toString());
    }, [currentCommission]);

    const onSubmit = (data: any) => {
        changeCommission.mutate(data, {
            onSuccess: () => {
                GlobalModalStorage.makeVisible(true, t("common.success"));
                mutate(mutateData);
                handleMenu();
            },
            onError: (error) => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
            },
        });
    };

    if (openMenu)
        return (
            <ModalWrapper
                onClick={(e) => {
                    if (e.currentTarget === e.target) {
                        if (openMenu) {
                            handleMenu();
                        }
                    }
                }}>
                <EditModalWrapper>
                    <ModalHeader>
                        <h3>Edit convert commission</h3>
                        <img
                            style={{cursor: "pointer"}}
                            onClick={handleMenu}
                            width={14}
                            height={14}
                            src={"/images/buttons/close.png"}
                            alt={"close"}
                        />
                    </ModalHeader>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        {forms.map((it, idx) => (
                            <InputWrapper>
                                <Controller
                                    name={it.name}
                                    control={control}
                                    rules={it.rules}
                                    render={({field: {ref, ...field}}) => (
                                        <ValidationInputComponent
                                            {...field}
                                            maxLength={50}
                                            isError={!!errors[it.name]}
                                            label={it.label}
                                            style={{fontSize: 14}}
                                        />
                                    )}
                                />
                            </InputWrapper>
                        ))}
                        <StyledButton
                            type={"submit"}
                            variant="black"
                            style={{
                                width: "100%",
                                padding: "var(--16, 1rem)",
                                maxWidth: "100%",
                            }}
                            disabled={!isValid || changeCommission.isLoading}
                        >
                            {changeCommission.isLoading ? "Loading" : "Edit"}
                        </StyledButton>
                    </StyledForm>
                </EditModalWrapper>
            </ModalWrapper>
        );
};

export default EditCommissionModal;
