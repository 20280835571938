import { createGlobalStyle } from "styled-components";
const GlobalTimeStyle = createGlobalStyle`
  
  * {
    font-family: var(--font-regular);
  }

  body {
    overflow-x: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: calc(10px + 2vmin);
    font-family: var(--font-regular);
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
  }

  p {
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export default GlobalTimeStyle;
