import { PROJECT_NAME } from "../../config/serverConstants";

export const headerAuthLinks = [
  {
    to: "/dashboard",
    text: "menu.dashboard",
  },
  {
    to: "/assets",
    text: "menu.deposit",
  },
  PROJECT_NAME === "Def" && {
    to: "/markets/BTCUSDT",
    text: "menu.markets",
  },
  {
    to: "/trade-convert",
    text: "menu.convert",
  },
  {
    to: "/referrals",
    text: "menu.referrals",
  },
];

export const headerNotAuthLinks = [
  {
    to: "profit",
    text: "menu.profit",
  },
  {
    to: "principles",
    text: "menu.principles",
  },
  {
    to: "plans",
    text: "menu.plans",
  },
  {
    to: "cooperation",
    text: "menu.cooperation",
  },
];

export const adminLinks = [
  {
    to: "/admin/analytics",
    text: "menu.dashboard",
  },
  {
    to: "/admin/withdraw",
    text: "menu.withdrawRequests",
  },
  {
    to: "/admin/withdraw-history",
    text: "menu.withdrawHistory",
  },
  {
    to: "/admin/operations",
    text: "menu.historyOperations",
  },
  {
    to: "/admin/invest-pools",
    text: "Invest Pools",
  },
  {
    to: "/admin/currencies",
    text: "Currencies",
  },
  {
    to: "/admin/wallets",
    text: "Wallets",
  },
  {
    to: "/admin/commissions",
    text: "Commissions",
  },
];
