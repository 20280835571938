import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const FlexColumnBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
export const FlexColumnStart = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;
export const FlexRowBetween = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
export const FlexRowResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const FlexRowBetweenResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

interface StyledButtonProps {
  variant?: "primary" | "text" | "black";
  noRadius?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: ${({ noRadius }) => (noRadius ? "0px" : "9px")};
  cursor: pointer;
  background: ${({ variant, theme }) =>
    (variant === "black" && theme.colors.black) ||
    (variant === "text" && "none") ||
    "linear-gradient(100.72deg, #ff8a00, #d449f7)"};
  padding: 8px 12px;
  color: ${(props) =>
    props.variant === "primary" ? "#fff" : props.theme.colors.white};
  border: ${(props) =>
    props.variant === "text"
      ? "none"
      : `1px solid ${props.theme.colors.white}`};
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;

  p {
    font-size: 16px;
  }

  :disabled {
    background: ${({ theme }) => theme.colors.lightGrayButton};
    color: ${({ theme }) => theme.colors.grayButton};
  }
`;

interface StyledTradeButtonProps {
  buy?: boolean;
}

export const StyledTradeButton = styled(StyledButton)<StyledTradeButtonProps>`
  padding: 14px;
  background: ${({ buy, theme }) =>
    buy ? theme.colors.greenSuccess : theme.colors.redFail};
  border-radius: 6px;
`;

interface SelectButtonProps {
  selected?: boolean;
}

export const SelectButton = styled.button<SelectButtonProps>`
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  height: 30px;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.black : theme.colors.white};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 6px;
  padding: 0 12px;

  &:hover {
    opacity: 0.9;
  }
`;

export const SelectTextButton = styled.button<SelectButtonProps>`
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.black : theme.colors.gray};
  background: none;
  border: none;
  border-bottom: 1px solid
    ${({ selected, theme }) => (selected ? theme.colors.black : "none")};
  padding-bottom: 6px;
`;

export const StyledRegisterButton = styled(StyledButton)`
  width: 440px;
  margin-top: 14px;
  border-radius: 9px;
  height: 44px;
  font-weight: 600;
  background: linear-gradient(90.28deg, #ff8a00 0%, #d449f7 100%);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

StyledButton.defaultProps = {
  variant: "primary",
};

StyledRegisterButton.defaultProps = {
  variant: "primary",
};

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-style: normal;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
`;

export const LanguageSwitch = styled.span<{ active?: boolean }>`
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
  color: ${({ active, theme }) =>
    active ? theme.colors.pink : theme.colors.grayButton};

  :hover {
    color: ${(props) => props.active === false && props.theme.colors.secondary};
    transition: all 0.3s ease;
  }
`;

LanguageSwitch.defaultProps = {
  active: false,
};

export const StyledButtonTransparent = styled(StyledButton)<{ mini?: boolean }>`
  background: none;
  border-radius: 10px;
  padding: ${(props) => (props.mini ? "10px 14px" : "15px")};
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px;
  font-size: ${(props) => (props.mini ? "13px" : "16px")};
  font-weight: ${(props) => (props.mini ? "400" : "500")};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.blue};

  p {
    font-size: ${(props) => (props.mini ? "13px" : "16px")};
    margin: 0;
    padding: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

interface StyledInputProps {
  isFailed?: boolean;
  value?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 16px;
  padding: 20px;
  margin: 0;
  border-radius: 16px;
  border: 1px solid
    ${({ isFailed, theme }) =>
      isFailed ? theme.colors.redInput : theme.colors.white};
  background: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.black};

  &:focus {
    background: ${({ theme }) => theme.colors.inputBackground};
    border: 1px solid
      ${({ isFailed, theme }) =>
        isFailed ? theme.colors.redFail : theme.colors.inputBackground};
  }
`;

export const StyledTextArea = styled.textarea<StyledInputProps>`
  width: 100%;
  height: 300px;
  outline: none;
  font-size: 16px;
  padding: 18px 20px;
  padding-top: ${({ value }) => value && "28px"};
  padding-bottom: ${({ value }) => value && "8px"};
  margin: 0;
  border-radius: 4px;
  border: 1px solid
    ${({ isFailed, theme }) =>
      isFailed ? theme.colors.redInput : "rgb(219, 219, 219)"};
  background: ${({ theme }) => theme.colors.gray050};
  color: ${({ theme }) => theme.colors.black};

  &:focus {
    border: 1px solid
      ${({ isFailed, theme }) =>
        isFailed ? theme.colors.redFail : "rgb(118, 118, 118)"};
    padding-top: 16px;
    padding-bottom: 8px;
  }
`;

export const Modal = styled(FlexColumn)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  background: #fff;
  z-index: 999;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }
`;

export const ModalHeader = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 14px;
    font-weight: 600;
  }

  img {
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }
`;

export const EditModalWrapper = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.gray050};
  width: 540px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 6px 20px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

interface AppStyleProps {
  isLandingPage?: boolean;
  isAuth: boolean;
}

export const AppStyle = styled(FlexColumn)<AppStyleProps>`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  /* min-height: 800px; */
  background: ${({ theme }) => theme.colors.white};
`;

export const Page = styled(FlexColumn)`
  width: 100vw;
  min-height: calc(100vh - 443px);
  overflow-x: scroll;
  justify-content: start;
`;
export const AuthContent = styled(FlexColumn)`
  width: 100vw;
  height: 100vh;
  padding-top: 64px;
  justify-content: center;
`;

export const AuthWrapper = styled(FlexRow)`
  width: 100%;
  /* height: calc(100vh - 64px); */
  height: 100%;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const HalfContent = styled(FlexColumn)`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export const AuthModal = styled(FlexColumn)`
  padding: 84px 20px 26px;
  border-radius: 16px;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`;

export const AuthTitle = styled.h1`
  font-weight: 700;
  font-size: 26px;
`;

export const PassIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
`;

type IErrorTextProps = {
  hasError?: boolean;
};
export const ErrorText = styled.p<IErrorTextProps>`
  color: ${({ theme }) => theme.colors.redFail};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  display: ${(props) => (props.hasError ? "block" : "none")};
`;

ErrorText.defaultProps = {
  hasError: true,
};

export const InfoText = styled.p`
  color: ${({ theme }) => theme.colors.grayButton};
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  min-height: 14px;
  cursor: pointer;
`;

export const StatsWrapper = styled(FlexRowBetweenResponsive)`
  max-width: 1130px;
  justify-content: start;

  @media (min-width: 780px) and (max-width: 1100px) {
    gap: 2rem;
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 480px) and (max-width: 780px) {
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 480px) {
    gap: 20px;
    width: 100%;
    flex-direction: column;
  }
`;

export const AuthInputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 440px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledBg = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.pageBg};
  box-sizing: border-box;
  width: 100vw;
  padding: 24px;
  height: auto;
  min-height: 100vh;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-top: 94px;
  @media (max-width: 780px) {
    padding: 10px;
    padding-top: 94px;
  }
`;

interface TabsButtonProps {
  clicked: boolean;
}

export const TabsButton = styled.button<TabsButtonProps>`
  padding: 8px;
  background: ${({ clicked, theme }) =>
    clicked
      ? "linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%)"
      : theme.colors.lightGrayButton};
  color: ${({ clicked, theme }) =>
    clicked ? "#fff" : `${theme.colors.black}`};
  border-radius: 9px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }
`;
export const DepositTabsButton = styled(TabsButton)`
  color: ${({ clicked, theme }) =>
    clicked ? "#fff" : `${theme.colors.grayButton}`};
`;

export const AppTitleNormal = styled.h3`
  margin: 0;
  font-weight: 600;
`;
export const AppTextMedium = styled.p`
  font-size: 24px;
  font-weight: 600;
`;
export const AppTextNormal = styled.p`
  font-size: 18px;
  font-weight: 400;
`;
export const AppTextBlack = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const AppTextBig = styled.p`
  font-size: 24px;
  font-weight: 700;
`;
export const AppTextMini = styled.p`
  color: ${({ theme }) => theme.colors.grayButton};
  font-size: 12px;
  font-weight: 500;
`;

export const AppTextMiniBold = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

export const MarketText = styled.p`
  font-size: 14px;
  font-weight: 500;
`;
export const MarketTextMini = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
export const MarketTextBold = styled.p`
  font-size: 12px;
  font-weight: 600;
`;
export const MarketTextNormal = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;
export const MarketTextBlack = styled.p`
  font-size: 16px;
  font-weight: 700;
`;
export const TradeLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;

export const InputTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const CloseModalButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
  filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
`;

export const InputAndErrorWrapper = styled(FlexColumnStart)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const AssetsBlock = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.gray050};
  padding: 20px;
  border-radius: 20px;
`;

export const AssetsBlockHeader = styled(FlexRowBetween)`
  border: none;
  background: none;
  width: 960px;
  text-align: left;
  padding-bottom: 10px;

  p {
    color: ${({ theme }) => theme.colors.black};
  }

  @media (max-width: 1000px) {
    width: calc(100vw - 60px);
  }
`;
