import React, { useState } from "react";
import styled from "styled-components/macro";
import { useTheme } from "styled-components";
import {
  FlexColumn,
  FlexRowBetween,
  FlexRow,
} from "../../style/projectComponents";
import { IInvestPool } from "../../service/investpool-api/models";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import AdminInvestPoolModal from "../global/modal/AdminInvestPoolModal";
import { InvestPoolDto } from "../../service/api/generated";

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 14px 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray};
  background: none;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray050};
  }
`;
const Value = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;
const LastColumn = styled(FlexColumn)`
  width: 140px;
  align-items: end;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ShortName = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`;

interface AdminInvestPoolRowProps {
  iPool?: InvestPoolDto;
  id: number;
}

export const AdminInvestPoolRow: React.FC<AdminInvestPoolRowProps> = ({
  iPool,
  id,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const logo = iPool.logoDownloadLink;
  const apr =
    iPool.term === "flexible"
      ? `${iPool.flexibleAprFrom}%-${iPool.flexibleAprTo}%`
      : `${iPool.fixedApr}%`;

  const theme = useTheme();

  return (
    <>
      <Block
        style={{
          borderTop: id === 0 && `1px solid ${theme.colors.blueGray}`,
          background: iPool.isClosed && `${theme.colors.redInput}`,
        }}
        onClick={() => setVisibleModal(true)}
      >
        <FlexRowBetween style={{ alignItems: "center" }}>
          <FlexRow style={{ width: 150, justifyContent: "start" }}>
            <img src={logo} alt="" style={{ borderRadius: "100%" }} />
            <FlexColumn style={{ alignItems: "start" }}>
              <ShortName>{iPool.name}</ShortName>
            </FlexColumn>
          </FlexRow>
          <FlexColumn style={{ width: 140, alignItems: "end" }}>
            <Value>{iPool.availableCurrencies?.join(", ")}</Value>
          </FlexColumn>
          <FlexColumn style={{ width: 140, alignItems: "end" }}>
            <Value>{apr}</Value>
          </FlexColumn>
          <FlexColumn style={{ width: 140, alignItems: "end" }}>
            <Value>{capitalizeFirstLetter(iPool?.term)}</Value>
          </FlexColumn>
          <LastColumn>
            <Value>{capitalizeFirstLetter(iPool?.period)}</Value>
          </LastColumn>
        </FlexRowBetween>
      </Block>
      {visibleModal && (
        <AdminInvestPoolModal setIsOpen={setVisibleModal} investPool={iPool} />
      )}
    </>
  );
};
