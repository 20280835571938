import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  StyledTradeButton,
  TradeLabel,
} from "../../style/projectComponents";
import { Controller } from "react-hook-form";
import {
  isOrderAmountGood,
  isUsdOrderAmountGood,
} from "../WithdrawComponents/utils";
import { ValidationTradeInput } from "../global/ValidationTradeInput";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;

  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;

const OrderColumn = styled(FlexColumnStart)`
  margin-top: 16px;
  gap: 12px;
  width: 100%;
`;

const TradingColumn = styled(FlexColumn)`
  gap: 4px;
  width: 100%;
`;

interface TradingPanelSectorProps {
  long?: boolean;
  lastPrice: number;
  tradingPair: string;
  control: any;
  setValue: any;
  errors: any;
  watch: any;
  handleSubmit: any;
}

export const TradingPanelSector: React.FC<TradingPanelSectorProps> = ({
  long,
  lastPrice,
  tradingPair,
  control,
  setValue,
  errors,
  watch,
  handleSubmit,
}) => {
  const lastPriceValue = long ? watch("buyPrice") : watch("sellPrice");
  const lastAmountValue = long ? watch("buyAmount") : watch("sellAmount");
  const [tPair, setTPair] = useState(tradingPair);
  const priceValueString = long ? "buyPrice" : "sellPrice";
  const totalValueString = long ? "buyTotal" : "sellTotal";
  const amountValueString = long ? "buyAmount" : "sellAmount";

  useEffect(() => {
    if (
      lastPrice &&
      lastPrice > 0 &&
      (parseFloat(lastPriceValue) === 0 || null)
    ) {
      setValue(priceValueString, lastPrice.toString());
    }
    if (tradingPair !== tPair) {
      setTPair(tradingPair);
      setValue(priceValueString, lastPrice.toString());
    }
  }, [lastPrice, tradingPair]);

  const handlePriceChange = (newValue) => {
    if (
      newValue &&
      parseFloat(newValue) > 0 &&
      lastPriceValue &&
      parseFloat(lastPriceValue) > 0
    ) {
      const updatedValue = parseFloat(newValue) * parseFloat(lastAmountValue);
      if (updatedValue > 0) {
        setValue(totalValueString, updatedValue.toString());
      } else setValue(totalValueString, "");
    } else setValue(totalValueString, "");
  };

  const handleAmountChange = (newValue) => {
    if (
      newValue &&
      parseFloat(newValue) > 0 &&
      lastPriceValue &&
      parseFloat(lastPriceValue) > 0
    ) {
      const updatedValue = parseFloat(newValue) * parseFloat(lastPriceValue);
      if (updatedValue > 0) {
        setValue(totalValueString, updatedValue.toString());
      } else setValue(totalValueString, "");
    } else setValue(totalValueString, "");
  };

  const handleTotalChange = (newValue) => {
    if (
      newValue &&
      parseFloat(newValue) > 0 &&
      lastPriceValue &&
      parseFloat(lastPriceValue) > 0
    ) {
      const updatedValue = parseFloat(newValue) / parseFloat(lastPriceValue);
      if (updatedValue > 0) {
        setValue(amountValueString, updatedValue.toFixed(8));
      } else setValue(amountValueString, "");
    } else setValue(amountValueString, "");
  };

  const onSubmit = (data: any) => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <OrderColumn>
        <TradingColumn>
          <TradeLabel>Price</TradeLabel>
          <InputWrapper>
            <Controller
              name={priceValueString}
              control={control}
              rules={{ validate: isOrderAmountGood }}
              render={({ field: { ref, ...field } }) => (
                <ValidationTradeInput
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handlePriceChange(e.target.value);
                  }}
                  name={priceValueString}
                  type="text"
                  isError={!!errors.buyPrice}
                  label={priceValueString}
                />
              )}
            />
          </InputWrapper>
        </TradingColumn>
        <TradingColumn>
          <TradeLabel>Amount</TradeLabel>
          <InputWrapper>
            <Controller
              name={amountValueString}
              control={control}
              rules={{ validate: isOrderAmountGood }}
              render={({ field: { ref, ...field } }) => (
                <ValidationTradeInput
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handleAmountChange(e.target.value);
                  }}
                  type="text"
                  isError={!!errors.buyAmount}
                  label=""
                />
              )}
            />
          </InputWrapper>
        </TradingColumn>
        <TradingColumn>
          <TradeLabel>Total</TradeLabel>
          <InputWrapper>
            <Controller
              name={totalValueString}
              control={control}
              rules={{ validate: isUsdOrderAmountGood }}
              render={({ field: { ref, ...field } }) => (
                <ValidationTradeInput
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handleTotalChange(e.target.value);
                  }}
                  type="text"
                  isError={!!errors.buyTotal}
                  label=""
                />
              )}
            />
          </InputWrapper>
        </TradingColumn>
        <StyledTradeButton buy={long} type="submit" style={{ width: "100%" }}>
          {long ? "Buy" : "Sell"}
        </StyledTradeButton>
      </OrderColumn>
    </form>
  );
};
