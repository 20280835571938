import {IUserRole} from '../admin-api/models';


export type IUserListReq = {
    skip: number,
    take: number
}

export type IUserListResponse = {
    items: IUserItemResponse[],
    totalCount: number
}

export type IUserItemResponse = {
    id: string,
    firstName: string,
    middleName?: string,
    lastName: string,
    email: string,
    wallets: IWallet[]
}

export type IWallet = {
    id: string,
    name: string,
    currency: string,
    balance: number
    totalAccrualOfInterest: number
    percentOfAllAccrualOfInterest: number
    lastAccrualOfInterestPercents: number
}

export type IUserDetailsResponse = {
    id: string,
    firstName: string,
    middleName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    birthDate: string,
    roles: {
        id: string,
        name: string,
        type: IUserRole
    }[],
    wallets: IWallet[]
    twoFactorEnabled: boolean,
    totalBalanceInUsd: number,
    referralCode: string
}

export type ICurrentUserResponse = {
    id: string,
    name: string,
    roles: IUserRole[]
}

export type IUpdateUserInfo = {
    id: string,
    firstName: string,
    middleName: string,
    lastName: string,
    phoneNumber: string,
    birthDate: string
}

export type IDashboardItemsRequest = {
    walletId: string,
    takeLast: number
}

export type IGlobalDashboardItemsRequest = {
    userId: string,
    takeLast: number
}

export type IDashboardItemsResponse = {
    items: {
        date: string,
        balance: number,
        balanceInUsd: number,
        operationType: IDashboardOperationTypeRsp
    }[]
}

export type IGlobalDashboardItemsResponse = {
    totalProfit: number,
    currentInvestPoolsIncome: number,
    items: IGlobalDashboardItem[]
}

export type IGlobalDashboardItem = {
    date: string,
    balanceInUsd: number
}

export type IAccrualOfInterestDashboardItemsResponse = {
    items: {
        date: string,
        balanceDiff: number,
        balanceDiffInUsd: number,
        balanceDiffPercent: number
    }[]
}

export enum IDashboardOperationTypeRsp {
    withdrawalRequest = 'withdrawalRequest',
    deposit = 'deposit',
    accrualOfInterest = 'accrualOfInterest'
}

export type IGetUserOperationsRequest = {
    userId: string,
    statuses: IStatusOperation[],
    skip: number,
    take: number
}

export enum IStatusOperation {
    undefined = 'undefined',
    notApproved = 'notApproved',
    approved = 'approved',
    rejected = 'rejected',
    cancelled = 'cancelled'
}

export type IGetUserOperationsResponse = {
    items: IOperationItem[],
    totalCount: number
}

export type IOperationItem = {
    status: IStatusOperation,
    operationDate: string,
    completeDate?: string,
    wallet: {
        id: string,
        name: string,
        currency: string,
        balance: number,
        totalAccrualOfInterest: number,
        percentOfAllAccrualOfInterest: number,
        lastAccrualOfInterestPercents: number
    },
    walletOwnerId: string,
    walletOwnerEmail: string,
    balanceDiff: number,
    comment?: string,
    type?: string
}

export type IGetUserReferralsReq = {
    userId: string,
    skip: number,
    take: number
}

export type IGetUserReferralsRes = {
    items: IReferralItem[];
    totalProfit: number;
    totalCount: number;
}

export type IReferralItem = {
    id: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    level: number,
    profit: number
}

export type IUserInterface = {
    getAllUsersList(data: IUserListReq)
    getUserDetails(userId: string)
    getCurrentUser()
    updateUserInfo(data: IUpdateUserInfo)
    updatePassword(oldPassword: string, newPassword: string)
    getGlobalDashboardItems(data: IGlobalDashboardItemsRequest)
    getDashboardItems(data: IDashboardItemsRequest)
    getUserOperationsList(data: IGetUserOperationsRequest)
    getUserReferrals(data: IGetUserReferralsReq)
}
