import React, { useEffect } from "react";
import { FlexColumn, Page } from "../../style/projectComponents";
import { observer } from "mobx-react-lite";
import styled from "styled-components/macro";
import PageHeader from "./PageHeader";
import ConvertForm from "./ConvertForm";

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 30px;
  padding-bottom: 20px;
`;

const ConvertPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page style={{ justifyContent: "start" }}>
      <StyledBg>
        <PageHeader />
        <ConvertForm />
      </StyledBg>
    </Page>
  );
};

export default observer(ConvertPage);
