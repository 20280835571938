import React, { useContext, useState } from "react";
import {
  EditModalWrapper,
  FlexColumn,
  FlexRow,
  Modal,
  ModalHeader,
  StyledButton,
  TabsButton as TabsButtonDef,
} from "../../../style/projectComponents";
import { TabsButton as TabsButtonTime } from "../../../style/projectTimeComponents";
import styled from "styled-components/macro";
import { ValidationInputComponent } from "../../global/ValidationInputComponent";
import { useMutation, useQuery } from "react-query";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../config/query-client";
import NetworkService from "../../../service/network-api/NetworkApi";
import { CheckComponent } from "../../auth/Register/CheckComponent";
import { AddOrUpdateCurrencyNetworkInfo } from "../../../service/currency-api/models";
import CurrencyService from "../../../service/currency-api/CurrencyApi";
import { useGetApiNetworkGetNetworksList } from "../../../service/api/generated";
import { PROJECT_NAME } from "../../../config/serverConstants";

const TabsButton = PROJECT_NAME === "Time" ? TabsButtonTime : TabsButtonDef;

const LangModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.modalBg};
  justify-content: center;
  flex-direction: column;
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 1rem var(--4, 2rem) 3rem var(--4, 2rem);
  margin: 10px;

  .inps {
    gap: 6px;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }
`;

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

type IFormType = {
  name: "name" | "description" | "coinGeckoId";
  rules: any;
  type: string;
};

const forms: IFormType[] = [
  {
    name: "name",
    rules: {
      required: true,
    },
    type: "string",
  },
];

interface AddUpdateNetworkModalProps {
  openMenu: boolean;
  handleMenu: () => void;
  currencyId: string;
  name: string;
}

const AddUpdateNetworkModal: React.FC<AddUpdateNetworkModalProps> = ({
  openMenu,
  handleMenu,
  currencyId,
  name,
}) => {
  // const { data: networkList } = useQuery("network_list", () =>
  //   NetworkService.getNetworkList()
  // );
  const { data: networkList } = useGetApiNetworkGetNetworksList();
  const [networkId, setNetworkId] = useState<string | null>(null);
  const [contract, setContract] = useState<string | null>(null);
  const [isClicked, setIsClicked] = useState(false);

  const updateNetwork = useMutation((data: AddOrUpdateCurrencyNetworkInfo) =>
    CurrencyService.addOrUpdateCurrencyNetworkInfo(data)
  );
  const GlobalModalStorage = useContext(GlobalModalStore);
  const { t, i18n } = useTranslation();

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      isNative: isClicked,
      networkId,
      contract,
      currencyId,
    };
    updateNetwork.mutate(data, {
      onSuccess: () => {
        GlobalModalStorage.makeVisible(true, t("common.success"));
        queryClient.invalidateQueries({ queryKey: ["currency_list"] });
      },
      onError: () => {
        GlobalModalStorage.makeVisible(false, t("common.error"));
      },
    });
  };

  if (openMenu)
    return (
      <LangModalWrapper>
        <EditModalWrapper>
          <ModalHeader>
            <h3>Add or update network for {name}</h3>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleMenu}
              width={14}
              height={14}
              src={"/images/buttons/close.png"}
              alt={"close"}
            />
          </ModalHeader>
          <FlexRow style={{ gap: "16px" }}>
            {networkList?.data?.items.map((n) => (
              <TabsButton
                key={n.id}
                onClick={() => {
                  setNetworkId(n.id);
                }}
                clicked={n.id === networkId}
              >
                {n.name}
              </TabsButton>
            ))}
          </FlexRow>
          <StyledForm onSubmit={onSubmit}>
            <FlexColumn className={"inps"}>
              <InputWrapper>
                <ValidationInputComponent
                  value={contract}
                  onChange={(e) => {
                    setContract(e.target.value);
                    setIsClicked(false);
                  }}
                  maxLength={100}
                  label={"contract"}
                />
              </InputWrapper>
            </FlexColumn>
            <CheckComponent
              handleClick={() => {
                setIsClicked((prev) => !prev);
                setContract(null);
              }}
              isClicked={isClicked}
              text="is native"
            />
            <StyledButton
              type={"submit"}
              variant="black"
              style={{
                width: "100%",
                padding: "var(--16, 1rem)",
                maxWidth: "100%",
                marginTop: 16,
              }}
              disabled={updateNetwork.isLoading}
            >
              {updateNetwork.isLoading ? "Loading" : "Edit"}
            </StyledButton>
          </StyledForm>
        </EditModalWrapper>
      </LangModalWrapper>
    );
};

export default AddUpdateNetworkModal;
