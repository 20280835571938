import { Controller, useForm } from "react-hook-form";
import { ValidationInputComponent } from "../global/ValidationInputComponent";
import {
  ErrorText,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  InfoText,
  StyledRegisterButton,
} from "../../style/projectComponents";
import React, { FC, useContext, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { isAmountGood, isWalletGood } from "./utils";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import WithdrawalApi from "../../service/withdrawal-api/WithdrawalApi";
import { IWithdrawalRequest } from "../../service/withdrawal-api/models";
import { ValidationInputComponentMax } from "../global/ValidationInputComponentMax";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import toLocaleNumber from "../../utils/toLocaleNumber";
import { AvailableNetworksForDeposit } from "../../service/admin-api/models";
import { SelectLabelItem, StyledSelect } from "../global/StyledSelect";
import { queryClient } from "../../config/query-client";

const WithdrawBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 12px;
  width: 380px;

  @media (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }
`;
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  max-width: 380px;
  min-width: 310px;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;

export type IDepositDataProps = {
  userBalance: number;
  walletId: string;
  title: string;
  networks: AvailableNetworksForDeposit[];
};

export const WithdrawWindow: FC<IDepositDataProps> = ({
  userBalance,
  walletId,
  title,
  networks,
}) => {
  const {
    control,
    clearErrors,
    watch,
    setError,
    reset,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({ mode: "all" });

  const { t } = useTranslation();

  const networksForSelect = useMemo(
    () => networks?.map((n) => ({ label: n.name, value: n.id })),
    [networks]
  );

  const createWithdrawMutation = useMutation((data: IWithdrawalRequest) =>
    WithdrawalApi.createWithdrawal(data)
  );
  // const NetworkStore = useContext(NetworkStorage);

  // const GetNetworksList = useQuery(
  //   "networks_list",
  //   () => NetworkApi.getNetworkList(),
  //   {
  //     onSuccess: (data) => {
  //       NetworkStore.setNetworkList(data);
  //     },
  //     enabled: NetworkStore.networkList.items.length === 0,
  //   }
  // );
  const GlobalModalStorage = useContext(GlobalModalStore);

  const onSubmit = (data) => {
    const req: IWithdrawalRequest = {
      currency: title,
      // destinationNetworkId: NetworkStore?.networkList?.items.find(
      //   (it) => it.name === "Default network"
      // ).id,
      destinationNetworkId: network?.id,
      destinationWalletNumber: data["withdrawal address"],
      walletId: walletId,
      withdrawalAmount: Number(data["Amount"]),
    };

    createWithdrawMutation.mutate(req, {
      onSuccess: (_data) => {
        queryClient.invalidateQueries({ queryKey: ["profile_data"] });
        queryClient.invalidateQueries({ queryKey: ["user_profile_data"] });
        queryClient.invalidateQueries({ queryKey: ["currency_list"] });
        GlobalModalStorage.makeVisible(
          true,
          t("common.withdrawSuccessNotification", { amount: watch("Amount") })
        );
        reset({
          "withdrawal address": "",
          Amount: "",
        });
      },
      onError: (_error) => {
        GlobalModalStorage.makeVisible(
          false,
          t("common.withdrawErrorNotification", { amount: watch("Amount") })
        );
        setError("badRequest", {
          type: "manual",
          message: "Bad request please try again",
        });
      },
    });
  };

  const setValueToMax = () => {
    if (userBalance > 0) {
      setValue("Amount", userBalance);
    }
  };

  // const withdrawRegex = findTypeByToken(title)?.regex || "";
  // const withdrawLabel = findTypeByToken(title)?.label || "";
  const [network, setNetwork] = useState<AvailableNetworksForDeposit>(
    networks?.[0]
  );
  const [networkOption, setNetworkOption] = useState<SelectLabelItem>(
    networksForSelect?.[0]
  );
  // const networkRegex = useMemo(() => {
  //   const currentNetwork = NetworkStore?.networkList?.items.find(
  //     (n) => n.id === network?.id
  //   );
  //   return currentNetwork ? currentNetwork.walletsValidationRegex : "";
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [NetworkStore?.networkList?.items, network?.id]);

  const theme = useTheme();
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: theme.colors.inputBackground,
    }),
  };

  return (
    <FlexColumn>
      <br />
      <StyledSelect
        styles={customStyles}
        options={networksForSelect}
        classNamePrefix={"Select"}
        value={networkOption}
        onChange={(e) => {
          const event = e as SelectLabelItem;
          setNetworkOption({ label: event.label, value: event.value });
          const network = networks.find((n) => n.id === event.value);
          setNetwork(network);
        }}
      />
      {network && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WithdrawBlock>
            <FlexColumnStart style={{ width: "100%" }}>
              <InputWrapper style={{ marginBottom: 20 }}>
                <Controller
                  name="withdrawal address"
                  control={control}
                  rules={isWalletGood(RegExp(network?.walletsValidationRegex))}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      type="text"
                      isError={!!errors["withdrawal address"]}
                      label={network?.name + " address"}
                    />
                  )}
                />
              </InputWrapper>

              <ErrorText hasError={!!errors["withdrawal address"]}>
                {t(errors["withdrawal address"]?.message?.toString())}
              </ErrorText>
            </FlexColumnStart>

            <FlexColumnStart style={{ width: "100%" }}>
              <InputWrapper style={{ marginBottom: 20 }}>
                <Controller
                  name="Amount"
                  control={control}
                  rules={isAmountGood(userBalance)}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponentMax
                      setValueToMax={setValueToMax}
                      {...field}
                      type="text"
                      isError={!!errors["Amount"]}
                      label={t("withdraw.amount")}
                    />
                  )}
                />
              </InputWrapper>

              <InfoText
                style={{ marginTop: 3, marginBottom: 3 }}
                onClick={setValueToMax}
              >
                {t("withdraw.available")}
                <span
                  style={{
                    color: `${({ theme }) => theme.colors.dark}`,
                    marginLeft: 10,
                  }}
                >
                  {toLocaleNumber(userBalance, 0, 8) || 0}
                </span>
              </InfoText>

              <ErrorText hasError={!!errors["Amount"]}>
                {t(errors["Amount"]?.message?.toString())}
              </ErrorText>
            </FlexColumnStart>

            <StyledRegisterButton
              disabled={createWithdrawMutation.isLoading || !isValid}
              onClick={() => clearErrors()}
              type={"submit"}
              style={{ width: "100%" }}
            >
              {t("withdraw.withdraw")}
            </StyledRegisterButton>
          </WithdrawBlock>
        </form>
      )}
    </FlexColumn>
  );
};
