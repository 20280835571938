import { Controller, useForm } from "react-hook-form";
import {
  CustomStyledInput,
  ValidationInputComponent,
} from "../../global/ValidationInputComponent";
import {
  AuthInputWrapper,
  ErrorText,
  FlexColumn,
  FlexRow,
  InputAndErrorWrapper,
  InputTitle,
  PassIcon,
  StyledRegisterButton as StyledRegisterButtonDef,
} from "../../../style/projectComponents";
import {
  StyledRegisterButton as StyledRegisterButtonTime,
  AuthInputWrapper as AuthInputWrapperTime,
} from "../../../style/projectTimeComponents";
import React, { FC, useContext, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import AuthApi from "../../../service/auth-api/AuthApi";
import { IAuthSignupRequest } from "../../../service/auth-api/models";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserStorage from "../../../storage/UserStorage/UserStorage";
import { finalInputs, isPhoneNumberGood } from "./SecUtils";
import jwtDecode from "jwt-decode";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import formatDateCustom from "../../../utils/formatDateCustom";
import { phoneCodes } from "../../../config/phoneCodes";
import InputMask from "react-input-mask";
import {
  INITIAL_STATE_REG,
  RegisterContext,
} from "../../../pages/RegisterPage";
import { DropdownComponent } from "../../global/DropdownComponent";
import { PROJECT_NAME } from "../../../config/serverConstants";

const StyledRegisterButton =
  PROJECT_NAME === "Def" ? StyledRegisterButtonDef : StyledRegisterButtonTime;

type IFormValues = {
  phoneNumber: string;
  password: string;
  email: string;
  retypePassword: string;
  badSignup: any;
  receivedReferralCode: string;
};

export type IFinalInput = {
  name:
    | "phoneNumber"
    | "password"
    | "email"
    | "retypePassword"
    | "badSignup"
    | "receivedReferralCode";
  label: string;
  type: string;
  rules: any;
};

const StyledAdvise = styled.p`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 440px;
  min-height: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PhoneWrapper = styled(FlexRow)`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  gap: 6px;
`;

const MaskWrapper = styled(FlexRow)`
  width: 100%;
  max-width: 210px;
`;

export const SecondStep: FC = (props) => {
  const navigate = useNavigate();

  const UserStore = useContext(UserStorage);
  const { formUser, setFormUser } = useContext(RegisterContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const refCode = searchParams.get("ref") || "";

  const {
    control,
    clearErrors,
    setValue,
    resetField,
    setError,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: {
      phoneNumber: formUser.phoneNumber,
      password: formUser.password,
      email: formUser.email,
      retypePassword: "",
      receivedReferralCode: refCode,
    },
  });

  const { t, i18n } = useTranslation();

  const GlobalModalStorage = useContext(GlobalModalStore);

  const signupMutation = useMutation(
    (data: IAuthSignupRequest) => AuthApi.signup(data),
    {
      onError: (error: any) => {
        setError("badSignup", {
          type: "manual",
          message: "auth.errors.password.serverError",
        });
        if (error?.response?.data?.errorCode === "ERR_AUTHUSER_EXIST") {
          GlobalModalStorage.makeVisible(
            false,
            t("auth.errors.password.serverRegErrorUserExist")
          );
        } else
          GlobalModalStorage.makeVisible(
            false,
            t("auth.errors.password.serverRegError")
          );
      },
      onSuccess: (data) => {
        UserStore.setTokens(data);
        UserStore.setUser(jwtDecode(data.accessToken));
        setFormUser(INITIAL_STATE_REG);
        navigate("/dashboard");
      },
    }
  );
  const onSubmit = (data) => {
    setFormUser({
      ...formUser,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      receivedReferralCode: data.receivedReferralCode,
    });
    const birthDate = formUser.birthDate;
    const sendData = {
      ...formUser,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      receivedReferralCode:
        (data.receivedReferralCode?.length > 0 && data.receivedReferralCode) ||
        null,
    };
    sendData.birthDate = formatDateCustom(birthDate);
    signupMutation.mutate(sendData);
  };
  const phone: IFinalInput = {
    name: "phoneNumber",
    label: "auth.phone",
    type: "phone",
    rules: isPhoneNumberGood,
  };

  const [itemId, setItemId] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const MaskPhoneInput = (props) => {
    const idx = phoneCodes.at(itemId).split(" ").at(0);
    return (
      <InputMask
        style={{ direction: "ltr" }}
        mask={`(${idx}) 999-999-99-99`}
        {...props}
        value={props.value}
        onChange={props.onChange}
      >
        {(inputProps) => <CustomStyledInput {...inputProps} />}
      </InputMask>
    );
  };

  const [isClicked, setIsClicked] = useState(true);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const [showPass, setShowPass] = useState(false);
  const [showRetypePass, setShowRetypePass] = useState(false);

  const handleCheckType = (name: string, typeDefault: any) => {
    switch (name) {
      case "password":
        return showPass ? "text" : "password";
      case "retypePassword":
        return showRetypePass ? "text" : "password";
      default:
        return typeDefault;
    }
  };

  const advisePassword = "settings.inputs.password.errors.pattern";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthInputWrapper style={{ marginBottom: 10 }}>
        <Controller
          rules={phone.rules}
          name={phone.name}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PhoneWrapper>
              <DropdownComponent
                className={"menu"}
                itemId={itemId}
                itemList={phoneCodes}
                setId={setItemId}
                openMenu={openMenu}
                setOpenMenu={(open: boolean) => {
                  resetField("phoneNumber");
                  setOpenMenu(open);
                }}
              />
              <MaskWrapper>
                <MaskPhoneInput
                  {...field}
                  isFailed={!!errors[phone.name]}
                  className={"phone"}
                  name={phone.name}
                  placeholder={t("adminUserDetail.phoneNumber.label")}
                  isError={!!errors[phone.name]}
                />
              </MaskWrapper>
            </PhoneWrapper>
          )}
        />
      </AuthInputWrapper>
      <ErrorText
        hasError={!!errors[phone.name]}
        style={{ maxWidth: 300, marginBottom: 10 }}
      >
        {t(errors[phone.name]?.message?.toString())}
      </ErrorText>
      {finalInputs.map((it, idx) => (
        <InputAndErrorWrapper key={idx}>
          <InputTitle style={{ width: "100%", textAlign: "left" }}>
            {t(it.label)}
          </InputTitle>
          <AuthInputWrapper
            style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}
          >
            <Controller
              name={it.name}
              control={control}
              rules={it.name === "retypePassword" ? it.rules(watch) : it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  name={it.name}
                  type={handleCheckType(it.name, it.type)}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />
            {it.name === "password" && (
              <PassIcon
                onClick={() => setShowPass((prev) => !prev)}
                src={showPass ? "/images/show.svg" : "/images/hide.svg"}
              />
            )}

            {it.name === "retypePassword" && (
              <PassIcon
                onClick={() => setShowRetypePass((prev) => !prev)}
                src={showRetypePass ? "/images/show.svg" : "/images/hide.svg"}
              />
            )}
          </AuthInputWrapper>

          {it.name === "password" && !errors[it.name] && (
            <StyledAdvise>{t(advisePassword)}</StyledAdvise>
          )}
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </InputAndErrorWrapper>
      ))}

      <StyledRegisterButton
        onClick={() => clearErrors()}
        type={"submit"}
        disabled={!isClicked}
      >
        {t("auth.reg_title")}
      </StyledRegisterButton>
    </form>
  );
};
