import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  FlexColumn,
  FlexRowResponsive,
  Page,
} from "../style/projectComponents";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ReferralLinkBlock } from "../components/ReferralsComponents/ReferralLinkBlock";
import { TotalReferralsInfoBlock } from "../components/ReferralsComponents/TotalReferralsInfoBlock";
import { useQuery } from "react-query";
import UserApi from "../service/user-api/UserApi";
import ProfileStorage from "../storage/ProfileStorage/ProfileStorage";
import { OperationsComponent } from "../components/DashboardComponents/OperationsComponent";
import { DataEmptyComponent } from "../components/global/DataEmptyComponent";
import { PaginationComponent } from "../components/global/PaginationComponent";
import { generatePagesArray } from "../utils/generatePagesArray";

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 55px;
  max-width: 1000px;
`;

const SettingsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 30px;
`;

const RefInfoWrapper = styled(FlexRowResponsive)`
  gap: 30px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 0 10px;
  }
`;

const ReferralsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const ProfileStore = useContext(ProfileStorage);

  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  const { data: referralsInfo, isLoading: userRefDataLoading } = useQuery(
    "user_referrals",
    () =>
      UserApi.getUserReferrals({
        userId: ProfileStore.profile.id,
        skip: page * itemsPerPage,
        take: itemsPerPage,
      }),
    {
      enabled: !!ProfileStore.profile.id,
    }
  );
  const totalReferrals = referralsInfo?.totalCount || 0;
  const referralsItems = referralsInfo?.items;
  const totalProfit = referralsInfo?.totalProfit || 0;

  const totalPages = useMemo(
    () =>
      referralsInfo?.totalCount
        ? Math.ceil(referralsInfo?.totalCount / itemsPerPage)
        : 1,
    [referralsInfo?.totalCount, itemsPerPage]
  );

  return (
    <Page>
      <StyledBg>
        <SettingsTitle>{t("common.myReferrals")}</SettingsTitle>
        <RefInfoWrapper>
          <TotalReferralsInfoBlock
            totalReferrals={totalReferrals}
            totalProfit={totalProfit}
          />
          <ReferralLinkBlock />
        </RefInfoWrapper>
        <OperationsWrapper>
          {referralsItems?.length > 0 ? (
            <OperationsComponent
              keys={["email", "level", "profit"]}
              operations={referralsItems}
              withTopBorder
            />
          ) : (
            <DataEmptyComponent isLoading={userRefDataLoading} />
          )}
          <PaginationComponent
            maxPages={totalPages}
            pageId={page}
            pagesNum={generatePagesArray(totalPages, page, 100)}
            setPageId={setPage}
          />
        </OperationsWrapper>
      </StyledBg>
    </Page>
  );
};

export default observer(ReferralsPage);
