import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";

type ValidationInputComponentProps = {
  value?: string;
  isError?: any;
  label?: string;
  ref?: any;
};

export const ValidationLabel = styled.label<{ isFocused?: any }>`
  display: ${({ isFocused }) => (isFocused ? "block" : "none")};
  font-size: 14px;
  position: absolute;
  left: 20px;
  margin-top: 10px;
  transition: all 1s;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 480px) {
    left: 50px;
  }
`;

interface StyledInputProps {
  isFailed?: boolean;
  value?: string;
}

const TradeInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
  padding-left: 25px;
  margin: 0;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid
    ${({ isFailed, theme }) =>
      isFailed ? theme.colors.redInput : theme.colors.gray050};
  width: 100%;
  &:focus {
    border: 1px solid
      ${({ isFailed, theme }) =>
        isFailed ? theme.colors.redFail : theme.colors.black};
  }
`;

export const ValidationTradeInput: React.FC<
  ValidationInputComponentProps & ComponentPropsWithoutRef<"input">
> = (props) => {
  const { value, isError, label, ref, ...other } = props;

  return (
    <TradeInput
      {...other}
      ref={ref}
      value={value}
      placeholder=""
      onChange={other.onChange}
      isFailed={isError}
    />
  );
};
