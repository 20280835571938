import { PROJECT_NAME } from "./serverConstants";

const phoneCodesTime = [
  "+1 USA",
  "+86 China",
  "+91 India",
  "+44 United Kingdom",
  "+33 France",
  "+49 Germany",
  "+994 Azerbaijan",
  "+7 Russia",
  "+82 South Korea",
  "+81 Japan",
  "+39 Italy",
  "+34 Spain",
  "+55 Brazil",
  "+61 Australia",
  "+971 United Arab Emirates",
  "+972 Israel",
  "+65 Singapore",
  "+86 China",
  "+52 Mexico",
  "+1 Canada",
  "+41 Switzerland",
  "+31 Netherlands",
  "+46 Sweden",
  "+55 Argentina",
  "+420 Czech Republic",
  "+45 Denmark",
  "+30 Greece",
  "+353 Ireland",
  "+47 Norway",
  "+63 Philippines",
  "+48 Poland",
  "+351 Portugal",
  "+86 China",
  "+65 Singapore",
  "+66 Thailand",
  "+90 Turkey",
  "+380 Ukraine",
  "+966 Saudi Arabia",
  "+43 Austria",
  "+358 Finland",
  "+36 Hungary",
  "+62 Indonesia",
  "+55 Brazil",
  "+52 Mexico",
  "+54 Argentina",
  "+57 Colombia",
  "+593 Ecuador",
  "+51 Peru",
  "+57 Venezuela",
  "+58 Venezuela",
  "+94 Sri Lanka",
];
const phoneCodesDef = [
  "+7 Russia",
  "+1 USA",
  "+994 Azerbaijan",
  "+86 China",
  "+91 India",
  "+44 United Kingdom",
  "+33 France",
  "+49 Germany",
  "+82 South Korea",
  "+81 Japan",
  "+39 Italy",
  "+34 Spain",
  "+55 Brazil",
  "+61 Australia",
  "+971 United Arab Emirates",
  "+972 Israel",
  "+65 Singapore",
  "+86 China",
  "+52 Mexico",
  "+1 Canada",
  "+41 Switzerland",
  "+31 Netherlands",
  "+46 Sweden",
  "+55 Argentina",
  "+420 Czech Republic",
  "+45 Denmark",
  "+30 Greece",
  "+353 Ireland",
  "+47 Norway",
  "+63 Philippines",
  "+48 Poland",
  "+351 Portugal",
  "+86 China",
  "+65 Singapore",
  "+66 Thailand",
  "+90 Turkey",
  "+380 Ukraine",
  "+966 Saudi Arabia",
  "+43 Austria",
  "+358 Finland",
  "+36 Hungary",
  "+62 Indonesia",
  "+55 Brazil",
  "+52 Mexico",
  "+54 Argentina",
  "+57 Colombia",
  "+593 Ecuador",
  "+51 Peru",
  "+57 Venezuela",
  "+58 Venezuela",
  "+94 Sri Lanka",
];

export const phoneCodes =
  PROJECT_NAME === "Def" ? phoneCodesDef : phoneCodesTime;
