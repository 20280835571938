import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  FlexRowBetween,
  FlexColumnStart,
  MarketText,
  FlexRow,
  StyledButton,
} from "../../style/projectComponents";
import { useTranslation } from "react-i18next";
import InvestModal from "../global/modal/InvestModal";
import { IInvestPool } from "../../service/investpool-api/models";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import WithdrawFromInvestModal from "../global/modal/WithdrawFromInvestModal";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { InvestPoolDto } from "../../service/api/generated";

const Block = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.gray050};
  width: 31.5%;
  cursor: pointer;
  text-decoration: none;
  border-radius: 12px;
  color: #000;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 24px;
  }

  @media (min-width: 780px) and (max-width: 1200px) {
    width: 48%;
    margin-bottom: 24px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray050};
  }
`;
const Value = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;
const Title = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  margin-bottom: 8px;
  font-weight: 700;
  height: 40px;
`;
const Tag = styled(MarketText)`
  font-size: 11px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 6px 8px;
  border-radius: 6px;
  font-weight: 500;
`;
const Image = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 12px 12px 0 0;
  background-size: 100%;
  transition: 0.3s linear;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1fbc89;

  &:hover {
    background-size: 120%;
  }
`;
const Content = styled(FlexColumnStart)`
  width: 100%;
  padding: 15px;
  min-height: 210px;
  color: ${({ theme }) => theme.colors.black};
`;

interface InvestMethodBlockProps {
  iPool?: InvestPoolDto;
}

export const InvestMethodCard: React.FC<InvestMethodBlockProps> = ({
  iPool,
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleWithdrawModal, setVisibleWithdrawModal] = useState(false);
  useDisableBodyScroll(visibleModal || visibleWithdrawModal);
  const image = iPool.imageDownloadLink;
  const apr =
    iPool.term === "flexible"
      ? `${iPool.flexibleAprFrom || 0}%-${iPool.flexibleAprTo || 0}%`
      : `${iPool.fixedApr || 0}%`;

  return (
    <>
      <Block onClick={() => setVisibleModal(true)}>
        <Image style={{ backgroundImage: `url(${image})` }} />
        <Content>
          <Title>{iPool.name}</Title>
          <FlexRow style={{ gap: 6 }}>
            {iPool.tags.map((tag, idx) => (
              <Tag key={idx}>{tag}</Tag>
            ))}
          </FlexRow>
          <FlexRowBetween style={{ marginTop: 15 }}>
            <FlexColumnStart>
              <MarketText style={{ fontSize: 12 }}>
                {t("invest.table.period")}
              </MarketText>
              <Value>{capitalizeFirstLetter(iPool?.period)}</Value>
            </FlexColumnStart>
            <FlexColumnStart style={{ alignItems: "end" }}>
              <MarketText style={{ fontSize: 12 }}>
                {t("invest.table.term")}
              </MarketText>
              <Value>{capitalizeFirstLetter(iPool?.term)}</Value>
            </FlexColumnStart>
          </FlexRowBetween>
          <FlexRowBetween style={{ marginTop: 16 }}>
            <FlexColumnStart>
              <MarketText style={{ fontSize: 12 }}>APR</MarketText>
              <Value>{apr}</Value>
            </FlexColumnStart>
            <StyledButton
              style={{ padding: 8, fontSize: 12, fontWeight: 600 }}
              variant="black"
            >
              {t("invest.invest")}
            </StyledButton>
          </FlexRowBetween>
        </Content>
      </Block>
      {visibleModal && (
        <InvestModal
          setWithdrawOpen={setVisibleWithdrawModal}
          setIsOpen={setVisibleModal}
          investPool={iPool}
        />
      )}
      {visibleWithdrawModal && (
        <WithdrawFromInvestModal
          setIsOpen={setVisibleWithdrawModal}
          setInvestOpen={setVisibleModal}
          investPool={iPool}
        />
      )}
    </>
  );
};
