import React from "react";
import styled from "styled-components/macro";
import {
  FlexColumn,
  FlexRowBetween,
  MarketText,
} from "../../style/projectComponents";
import { TradingPanelSector } from "./TradingPanelSector";

const Block = styled(FlexColumn)`
  width: calc(100% - 32px);
  min-height: 360px;
  justify-content: start;
  align-items: start;
  padding: 12px 16px;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;

  img {
    width: 18px;
  }

  p {
    margin: 0;
    text-align: start;
    width: 100%;
  }
`;

const BlockHeader = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray};
  padding-top: 12px;
`;

const Title = styled(MarketText)`
  color: #000;
  width: 50px !important;
  border-bottom: 1px solid #000;
  padding-bottom: 4px;
`;

const TradingPanelsWrapper = styled(FlexRowBetween)`
  width: 100%;
  gap: 10px;
  @media screen and (max-width: 600px) {
    gap: 5px;
  }
`;

interface OrderBookPanelProps {
  lastPrice: number;
  tradingPair: string;
  control: any;
  setValue: any;
  errors: any;
  watch: any;
  handleSubmit: any;
}

export const TradingPanel: React.FC<OrderBookPanelProps> = ({
  lastPrice,
  tradingPair,
  control,
  setValue,
  errors,
  watch,
  handleSubmit,
}) => {
  return (
    <Block>
      <BlockHeader>
        <Title>Market</Title>
      </BlockHeader>
      <TradingPanelsWrapper>
        <TradingPanelSector
          long
          lastPrice={lastPrice}
          tradingPair={tradingPair}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          handleSubmit={handleSubmit}
        />
        <TradingPanelSector
          lastPrice={lastPrice}
          tradingPair={tradingPair}
          control={control}
          setValue={setValue}
          errors={errors}
          watch={watch}
          handleSubmit={handleSubmit}
        />
      </TradingPanelsWrapper>
    </Block>
  );
};
