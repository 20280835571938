import React from "react";
import styled, { useTheme } from "styled-components/macro";
import { AppTextMini, FlexRow } from "../../style/projectComponents";
import { formatNumber } from "../../utils/formatNumber";

const Block = styled(FlexRow)`
  width: 95%;
  height: 20px;
  padding: 0 10px;
  cursor: pointer;

  p {
    margin: 0;
    text-align: start;
    width: 33.3%;
    font-weight: 600;
    z-index: 2;
  }
`;

const VolumeTracker = styled(FlexRow)`
  height: 20px;
  width: 300px;
  position: absolute;
  justify-content: end;
  z-index: 1;
`;

interface AnimatedVolumeProps {
  percent: number;
}

const AnimatedVolume = styled.div<AnimatedVolumeProps>`
  height: 20px;
  // console error
  /* width: ${(props: AnimatedVolumeProps) => props.percent + "%"}; */
`;

interface OrderBookPanelProps {
  price: number;
  amount: number;
  total: number;
  isBid?: boolean;
  highestVolume: number;
  setPriceValue: any;
}

export const OrderItem: React.FC<OrderBookPanelProps> = ({
  price,
  amount,
  total,
  isBid,
  highestVolume,
  setPriceValue,
}) => {
  const percent = (amount / highestVolume) * 100;
  const theme = useTheme();

  return (
    <Block
      onClick={() => setPriceValue(!isBid ? "buyPrice" : "sellPrice", price)}
    >
      <AppTextMini
        style={{
          color: isBid ? theme.colors.redFail : theme.colors.greenSuccess,
        }}
      >
        {price}
      </AppTextMini>
      <AppTextMini
        style={{
          color: theme.colors.black,
          textAlign: "right",
        }}
      >
        {amount}
      </AppTextMini>
      <AppTextMini
        style={{
          color: theme.colors.black,
          textAlign: "right",
        }}
      >
        {formatNumber(total, 3)}
      </AppTextMini>
      <VolumeTracker>
        <AnimatedVolume
          style={{
            background: isBid
              ? theme.colors.redMarket
              : theme.colors.greenMarket,
            width: `${percent}%`,
          }}
          percent={percent}
        />
      </VolumeTracker>
    </Block>
  );
};
