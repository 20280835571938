import {
  IAccrualInvestPoolReq,
  ICreateInvestPoolReq,
  IGetAllInvestPoolsResponse,
  IInvestPoolInterface,
  IInvestPoolReq,
  IWithdrawPoolReq,
} from "./models";
import { apiClient } from "../../config/serverConstants";
import { arrayToStringAndAppend } from "../../utils/arrayToStringAndAppend";

class InvestPoolService implements IInvestPoolInterface {
  async getAllInvestPools() {
    const response = await apiClient.get("/api/InvestPool/getAllInvestPools");
    return response?.data as IGetAllInvestPoolsResponse;
  }
  async createInvestPool(data: ICreateInvestPoolReq) {
    const newData = new FormData();

    newData.append("Name", data.name);
    newData.append("Description", data.description);
    newData.append("Logo", data.logo);
    newData.append("Image", data.image);
    arrayToStringAndAppend(data.tags, newData, "Tags");
    arrayToStringAndAppend(
      data.availableCurrenciesIds,
      newData,
      "AvailableCurrenciesIds"
    );
    newData.append("Period", data.period);
    newData.append("Term", data.term);
    newData.append("FirstApr", data.firstApr?.toString());
    newData.append("SecondApr", data.secondApr?.toString() || "");
    newData.append("UserLimit", data.userLimit?.toString() || "");
    newData.append("TotalLimit", data.totalLimit?.toString());
    newData.append("FairAccrual", data.fairAccrual ? "true" : "false");
    newData.append(
      "EnableAccrualInterestFromReferal",
      data.enableAccrualInterestFromReferal ? "true" : "false"
    );
    newData.append("HoldInDays", data.holdInDays?.toString());

    const response = await apiClient.post(
      "/api/InvestPool/createInvestPool",
      newData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  async investInInvestmentPool(data: IInvestPoolReq) {
    const response = await apiClient.post(
      "/api/InvestPool/investInInvestmentPool",
      data
    );
    return response.data as any;
  }

  // TODO: fix link after fix backend
  async closeInvestPool(data: { poolId: string }) {
    const response = await apiClient.post(
      "/api/InvestPool/closeInvestPool",
      data
    );
    return response.data as any;
  }

  async accrualOfInterestInInvestPool(data: IAccrualInvestPoolReq) {
    const response = await apiClient.post(
      "/api/InvestPool/accrualOfInterestInInvestPool",
      data
    );
    return response.data as any;
  }

  async withdrawalFromInvestmentPool(data: IWithdrawPoolReq) {
    const response = await apiClient.post(
      "/api/InvestPool/withdrawalFromInvestmentPool",
      data
    );
    return response.data as any;
  }
}

export default new InvestPoolService();
