/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * DI.KC.Portal.Backend
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import { apiClient } from '../../config/serverConstants';
export type GetApiUserGetUserReferralsParams = {
userId?: string;
skip?: number;
take?: number;
};

export type GetApiUserGetAllUsersListParams = {
skip?: number;
take?: number;
};

export type GetApiNetworkGetDepositWalletParams = {
networkId?: string;
};

export type PostApiInvestPoolCreateInvestPoolBody = {
  AvailableCurrenciesIds?: string[];
  Description?: string;
  EnableAccrualInterestFromReferral?: boolean;
  FairAccrual?: boolean;
  FirstApr?: number;
  HoldInDays?: number;
  Image?: Blob;
  Logo?: Blob;
  Name?: string;
  Period?: Period;
  SecondApr?: number;
  Tags?: string[];
  Term?: InvestPoolTerm;
  TotalLimit?: number;
  UserLimit?: number;
};

export type GetApiInvestPoolGetAllInvestPoolsParams = {
userId?: string;
skip?: number;
take?: number;
};

export type PostApiFileUploadBody = {
  Files?: Blob[];
  Type?: FileType;
};

export interface WithdrawalRequestDto {
  /** @nullable */
  comment?: string | null;
  createdOn?: string;
  currency?: string;
  destinationNetwork?: NetworkDto;
  destinationWalletNumber?: string;
  status?: OperationStatus;
  /** @nullable */
  transactionNumber?: string | null;
  /** @nullable */
  updatedOn?: string | null;
  userId?: string;
  userName?: string;
  walletId?: string;
  withdrawalAmount?: number;
  withdrawalRequestId?: string;
}

export interface WithdrawalFromInvestmentPoolCommand {
  currencyId?: string;
  investPoolId?: string;
  value?: number;
}

export interface WalletDto {
  balance?: number;
  currency?: string;
  id?: string;
  name?: string;
}

export interface WalletDetailedDto {
  balance?: number;
  currency?: string;
  id?: string;
  lastAccrualOfInterest?: number;
  lastAccrualOfInterestPercents?: number;
  name?: string;
  totalAccrualOfInterest?: number;
}

export interface UsersStatisticDto {
  registrationsPerDays?: RegistrationsPerDayDto[];
  totalUsersCount?: number;
}

export interface UserOperationDto {
  balanceDiff?: number;
  balanceDiffInUsd?: number;
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  completeDate?: string | null;
  operationDate?: string;
  status?: OperationStatus;
  type?: OperationType;
  wallet?: WalletDto;
  walletOwnerEmail?: string;
  walletOwnerId?: string;
}

export interface UserDto {
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  /** @nullable */
  middleName?: string | null;
  totalBalance?: number;
  wallets?: WalletDto[];
}

export interface UserDetailsDto {
  birthDate?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  /** @nullable */
  middleName?: string | null;
  phoneNumber?: string;
  referralCode?: string;
  roles?: RoleDto[];
  totalBalanceInUsd?: number;
  twoFactorEnabled?: boolean;
  wallets?: WalletDetailedDto[];
}

export interface UpdateWalletCommand {
  /** @nullable */
  name?: string | null;
  walletId?: string;
}

export interface UpdateUserRolesCommand {
  rolesIds?: string[];
  userId?: string;
}

export interface UpdateUserInfoCommand {
  birthDate?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  /** @nullable */
  middleName?: string | null;
  phoneNumber?: string;
}

export interface UpdatePasswordCommand {
  newPassword?: string;
  oldPassword?: string;
}

export interface UpdateNetworkCommand {
  /** @nullable */
  evmCompatible?: boolean | null;
  /** @nullable */
  fullName?: string | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  nodeUrl?: string | null;
  /** @nullable */
  walletsValidationRegex?: string | null;
}

export interface UpdateInvestPoolCommand {
  /** @nullable */
  availableCurrenciesIds?: string[] | null;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  fairAccrual?: boolean | null;
  /** @nullable */
  name?: string | null;
  poolId?: string;
  /** @nullable */
  tags?: string[] | null;
  /** @nullable */
  totalLimit?: number | null;
  /** @nullable */
  userLimit?: number | null;
}

export interface UpdateDepositWalletQuery {
  id?: string;
  newAddress?: string;
}

export interface UpdateDepositWalletOwnerQuery {
  id?: string;
  /** @nullable */
  newOwnerId?: string | null;
}

export interface UpdateCurrencyCommand {
  /** @nullable */
  coinGeckoId?: string | null;
  /** @nullable */
  description?: string | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  withdrawalCommission?: number | null;
}

export interface SystemBalancesPerDayDto {
  date?: string;
  /** @nullable */
  totalBalance?: number | null;
  totalBalanceInUsd?: number;
}

export interface SystemBalancesPerYearDto {
  systemBalancesPerDays?: SystemBalancesPerDayDto[];
  year?: number;
}

export interface SystemBalanceStatisticDto {
  /** @nullable */
  currency?: string | null;
  systemBalancesPerYears?: SystemBalancesPerYearDto[];
}

export interface SignUpCommand {
  birthDate?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  /** @nullable */
  middleName?: string | null;
  password?: string;
  phoneNumber?: string;
  /** @nullable */
  receivedReferralCode?: string | null;
}

export interface SignInCommand {
  email?: string;
  password?: string;
  /** @nullable */
  twoFactorCode?: string | null;
}

export type RoleType = typeof RoleType[keyof typeof RoleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
  undefined: 'undefined',
  user: 'user',
  admin: 'admin',
} as const;

export interface RoleDto {
  id?: string;
  name?: string;
  type?: RoleType;
}

export interface ResetPasswordCommand {
  newPassword?: string;
  resetToken?: string;
  userId?: string;
}

export interface RemoveDepositWalletCommand {
  id?: string;
}

export interface RejectWithdrawalRequestCommand {
  comment?: string;
  withdrawalId?: string;
}

export interface RegistrationsPerDayDto {
  date?: string;
  registrationsCount?: number;
}

export interface RefreshTokenCommand {
  accessToken?: string;
  refreshToken?: string;
}

export interface ReferralDto {
  /** @nullable */
  email?: string | null;
  /** @nullable */
  firstName?: string | null;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  lastName?: string | null;
  level?: number;
  /** @nullable */
  middleName?: string | null;
  profit?: number;
}

export type Period = typeof Period[keyof typeof Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Period = {
  once: 'once',
  daily: 'daily',
  monthly: 'monthly',
  yearly: 'yearly',
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationType = {
  withdrawalRequest: 'withdrawalRequest',
  deposit: 'deposit',
  accrualOfInterest: 'accrualOfInterest',
  investPoolTransaction: 'investPoolTransaction',
  accrualOfInterestFromInvestPool: 'accrualOfInterestFromInvestPool',
  exchange: 'exchange',
  commission: 'commission',
  accrualOfInterestFromRefral: 'accrualOfInterestFromRefral',
} as const;

export type OperationStatus = typeof OperationStatus[keyof typeof OperationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationStatus = {
  undefined: 'undefined',
  notApproved: 'notApproved',
  approved: 'approved',
  rejected: 'rejected',
  cancelled: 'cancelled',
} as const;

export interface NetworkDto {
  evmCompatible?: boolean;
  fullName?: string;
  id?: string;
  name?: string;
  walletsValidationRegex?: string;
}

export interface InvestPoolWalletDto {
  availableBalance?: number;
  balance?: number;
  balanceInUsd?: number;
  createDate?: string;
  currencyId?: string;
  currencyName?: string;
  id?: string;
  totalIncome?: number;
}

export interface InvestPoolTotalWalletDto {
  balance?: number;
  currencyName?: string;
}

export type InvestPoolTerm = typeof InvestPoolTerm[keyof typeof InvestPoolTerm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestPoolTerm = {
  fixed: 'fixed',
  flexible: 'flexible',
} as const;

export interface InvestPoolDto {
  availableCurrencies?: string[];
  description?: string;
  fairAccrual?: boolean;
  /** @nullable */
  fixedApr?: number | null;
  /** @nullable */
  flexibleAprFrom?: number | null;
  /** @nullable */
  flexibleAprTo?: number | null;
  holdInDays?: number;
  id?: string;
  /** @nullable */
  imageDownloadLink?: string | null;
  investedByUserInUsd?: number;
  isClosed?: boolean;
  /** @nullable */
  logoDownloadLink?: string | null;
  name?: string;
  /** @nullable */
  nearestAccountingDay?: string | null;
  period?: Period;
  tags?: string[];
  term?: InvestPoolTerm;
  totalBalances?: InvestPoolTotalWalletDto[];
  totalInvestedInUsd?: number;
  /** @nullable */
  totalLimit?: number | null;
  /** @nullable */
  userLimit?: number | null;
  userWallets?: InvestPoolWalletDto[];
}

export interface InvestInInvestmentPoolCommand {
  investPoolId?: string;
  tokenId?: string;
  value?: number;
}

export interface GlobalDashboardItemDto {
  balanceInUsd?: number;
  date?: string;
}

export interface GetWithdrawalRequestsResponse {
  items?: WithdrawalRequestDto[];
  totalCount?: number;
}

export interface GetWithdrawalRequestsQuery {
  skip?: number;
  /** @nullable */
  statuses?: OperationStatus[] | null;
  take?: number;
}

export interface GetUsersReferralListResponse {
  items?: ReferralDto[];
  totalCount?: number;
  totalProfit?: number;
}

export interface GetUsersListResponse {
  items?: UserDto[];
  totalCount?: number;
}

export interface GetUserOperationsListResponse {
  items?: UserOperationDto[];
  totalCount?: number;
}

export interface GetUserOperationsListQuery {
  skip?: number;
  /** @nullable */
  statuses?: OperationStatus[] | null;
  take?: number;
  /** @nullable */
  types?: OperationType[] | null;
  userId?: string;
}

export interface GetUserDetailsQuery {
  userId?: string;
}

export interface GetNetworksListResponse {
  items?: NetworkDto[];
}

export interface GetInvestPoolsResponse {
  items?: InvestPoolDto[];
  totalCount?: number;
}

export interface GetInvestPoolQuery {
  id?: string;
}

export interface GetGlobalDashboardItemsResponse {
  currentInvestPoolsIncome?: number;
  items?: GlobalDashboardItemDto[];
  totalProfit?: number;
}

export interface GetGlobalDashboardItemsQuery {
  takeLast?: number;
  /** @nullable */
  userId?: string | null;
}

export interface GetFilesResponse {
  items?: FileDto[];
  totalCount?: number;
}

export interface GetFilesQuery {
  skip?: number;
  take?: number;
  type?: FileType;
}

export interface GetExchangeRateQuery {
  /** @nullable */
  firstTokenAmount?: number | null;
  firstTokenId?: string;
  secondTokenId?: string;
}

export interface GetExchangeCommissionsStatisticsQuery {
  /** @nullable */
  from?: string | null;
  skip?: number;
  take?: number;
  /** @nullable */
  to?: string | null;
  /** @nullable */
  userId?: string | null;
}

export interface GetExchangeCommissionsStatisticResponse {
  items?: AdminOperationDto[];
  totalCount?: number;
  totalProfitInUsd?: number;
}

export interface GetDashboardItemsResponse {
  currentInvestPoolsIncome?: number;
  items?: DashboardItemDto[];
  totalProfit?: number;
}

export interface GetDashboardItemsQuery {
  takeLast?: number;
  walletId?: string;
}

export interface GetCurrencyNetworkInfoQuery {
  currencyId?: string;
}

export interface GetCurrenciesResponse {
  currencies?: CurrencyDto[];
  depositWallets?: DepositWalletDto[];
}

export interface GetAllRolesResponse {
  roles?: RoleDto[];
}

export interface GetAllOperationsListResponse {
  items?: AdminOperationDto[];
  totalCount?: number;
}

export interface GetAllOperationsListQuery {
  skip?: number;
  /** @nullable */
  statuses?: OperationStatus[] | null;
  take?: number;
  /** @nullable */
  types?: OperationType[] | null;
  /** @nullable */
  userId?: string | null;
}

export interface GetAllDepositWalletsResponse {
  items?: DepositWalletDto[];
  totalCount?: number;
}

export interface GetAllDepositWalletsQuery {
  /** @nullable */
  evmComatable?: boolean | null;
  /** @nullable */
  networkId?: string | null;
  skip?: number;
  take?: number;
}

export interface GetAccrualOfInterestDashboardItemsResponse {
  items?: AccrualOfInterestDashboardItemDto[];
}

export interface GetAccrualOfInterestDashboardItemsQuery {
  takeLast?: number;
  walletId?: string;
}

export interface ForgotPasswordCommand {
  email?: string;
}

export type FileType = typeof FileType[keyof typeof FileType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileType = {
  investPoolLogo: 'investPoolLogo',
  investPoolImage: 'investPoolImage',
} as const;

export interface FileDto {
  downloadLink?: string;
  id?: string;
  type?: FileType;
  uploadDate?: string;
}

export interface ExchangeRateDto {
  commissionPercent?: number;
  /** @nullable */
  commissionValue?: number | null;
  exchangeRate?: number;
  /** @nullable */
  exchangeResult?: number | null;
}

export interface ExchangeCurrenciesCommand {
  firstTokenAmount?: number;
  firstTokenId?: string;
  secondTokenId?: string;
}

export interface DisableTwoFactorCommand {
  twoFactorCode?: string;
}

export interface DepositsPerDayDto {
  date?: string;
  /** @nullable */
  depositsAmount?: number | null;
  depositsAmountInUsd?: number;
  depositsCount?: number;
}

export interface DepositsPerYearDto {
  depositsPerDays?: DepositsPerDayDto[];
  totalDepositsCount?: number;
  year?: number;
}

export interface DepositsStatisticDto {
  /** @nullable */
  currency?: string | null;
  depositsPerYears?: DepositsPerYearDto[];
  /** @nullable */
  summaryDepositsAmount?: number | null;
  summaryDepositsAmountInUsd?: number;
}

export interface GetFullStatisticResponse {
  balancesStatistics?: BalancesStatisticDto[];
  currentAllInvestPoolsIncomeInUsd?: number;
  depositsStatistics?: DepositsStatisticDto[];
  systemBalancesStatistics?: SystemBalanceStatisticDto[];
  totalWalletsBalanceInUsd?: number;
  usersStatistic?: UsersStatisticDto;
}

export interface DepositWalletDto {
  address?: string;
  evm?: boolean;
  id?: string;
  /** @nullable */
  networkId?: string | null;
  /** @nullable */
  networkName?: string | null;
  /** @nullable */
  ownerId?: string | null;
}

export interface DepositMoneyCommand {
  amount?: number;
  /** @nullable */
  comment?: string | null;
  currencyId?: string;
  userId?: string;
}

export interface DeleteNetworkCommand {
  id?: string;
}

export interface DashboardItemDto {
  balance?: number;
  balanceInUsd?: number;
  date?: string;
  operationType?: OperationType;
}

export interface CurrentUserDto {
  id?: string;
  name?: string;
  roles?: RoleType[];
}

export interface CurrencyNetworkInfoDto {
  contractAddress?: string;
  currencyId?: string;
  id?: string;
  networkId?: string;
  networkName?: string;
}

export interface CurrencyDto {
  availableNetworksForDeposit?: NetworkDto[];
  /** @nullable */
  coinGeckoId?: string | null;
  description?: string;
  id?: string;
  name?: string;
  /** @nullable */
  usdPrice?: number | null;
  /** @nullable */
  withdrawalCommission?: number | null;
}

export interface CreateWithdrawalRequestCommand {
  destinationNetworkId?: string;
  destinationWalletNumber?: string;
  walletId?: string;
  withdrawalAmount?: number;
}

export interface CreateWalletCommand {
  currencyId?: string;
  name?: string;
  userId?: string;
}

export interface CreateNetworkCommand {
  evmCompatible?: boolean;
  fullName?: string;
  name?: string;
  /** @nullable */
  nodeUrl?: string | null;
  walletsValidationRegex?: string;
}

export interface CreateCurrencyCommand {
  /** @nullable */
  coinGeckoId?: string | null;
  description?: string;
  name?: string;
  /** @nullable */
  withdrawalCommission?: number | null;
}

export interface ConfirmTwoFactorCommand {
  twoFactorCode?: string;
}

export interface CloseInvestPoolCommand {
  poolId?: string;
}

export interface ChangeExchangeCommissionCommand {
  newExchangeCommission?: number;
}

export interface CancelWithdrawalRequestCommand {
  withdrawalId?: string;
}

export interface BalancesStatisticDto {
  balancesFrom0To1000?: number;
  balancesFrom10000To20000?: number;
  balancesFrom1000To5000?: number;
  balancesFrom20000To50000?: number;
  balancesFrom5000To10000?: number;
  balancesOverThan50000?: number;
  balancesZero?: number;
  /** @nullable */
  currency?: string | null;
  /** @nullable */
  summaryBalance?: number | null;
  summaryBalanceInUsd?: number;
}

export interface AuthResponse {
  /** @nullable */
  accessToken?: string | null;
  /** @nullable */
  refreshToken?: string | null;
  requiresTwoFactor?: boolean;
}

export interface ApproveWithdrawalRequestCommand {
  transactionNumber?: string;
  withdrawalId?: string;
}

export interface AdminOperationDto {
  balanceDiff?: number;
  balanceDiffInUsd?: number;
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  completeDate?: string | null;
  /** @nullable */
  operationActorEmail?: string | null;
  /** @nullable */
  operationActorId?: string | null;
  operationDate?: string;
  status?: OperationStatus;
  type?: OperationType;
  wallet?: WalletDto;
  walletOwnerEmail?: string;
  walletOwnerId?: string;
}

export interface AddOrUpdateCurrencyNetworkInfoCommand {
  /** @nullable */
  contract?: string | null;
  currencyId?: string;
  isNative?: boolean;
  networkId?: string;
}

export interface AddDepositWalletsCommand {
  addresses?: string[];
  networkId?: string;
}

export interface AccrualOfInterestInInvestPoolCommand {
  id?: string;
  percents?: number;
}

export interface AccrualOfInterestDashboardItemDto {
  balanceDiff?: number;
  balanceDiffInUsd?: number;
  balanceDiffPercent?: number;
  date?: string;
}

export interface AccrualOfInterestCommand {
  /** @nullable */
  comment?: string | null;
  currencyId?: string;
  percents?: number;
  /** @nullable */
  walletId?: string | null;
}





export const getApiAdminGetAllRoles = (
    
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetAllRolesResponse>(
      {url: `/api/Admin/getAllRoles`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiAdminGetAllRolesQueryKey = () => {
    return [`/api/Admin/getAllRoles`] as const;
    }

    
export const getGetApiAdminGetAllRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminGetAllRoles>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetAllRoles>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminGetAllRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminGetAllRoles>>> = ({ signal }) => getApiAdminGetAllRoles(signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetAllRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminGetAllRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminGetAllRoles>>>
export type GetApiAdminGetAllRolesQueryError = unknown



export function useGetApiAdminGetAllRoles<TData = Awaited<ReturnType<typeof getApiAdminGetAllRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetAllRoles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminGetAllRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminUpdateUserRoles = (
    updateUserRolesCommand: UpdateUserRolesCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/updateUserRoles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRolesCommand
    },
      );
    }
  


export const getPostApiAdminUpdateUserRolesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>, TError,{data: UpdateUserRolesCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>, TError,{data: UpdateUserRolesCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>, {data: UpdateUserRolesCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminUpdateUserRoles(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminUpdateUserRolesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>>
    export type PostApiAdminUpdateUserRolesMutationBody = UpdateUserRolesCommand
    export type PostApiAdminUpdateUserRolesMutationError = unknown

    export const usePostApiAdminUpdateUserRoles = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>, TError,{data: UpdateUserRolesCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminUpdateUserRoles>>,
        TError,
        {data: UpdateUserRolesCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminUpdateUserRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminDepositMoney = (
    depositMoneyCommand: DepositMoneyCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/depositMoney`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: depositMoneyCommand
    },
      );
    }
  


export const getPostApiAdminDepositMoneyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminDepositMoney>>, TError,{data: DepositMoneyCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminDepositMoney>>, TError,{data: DepositMoneyCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminDepositMoney>>, {data: DepositMoneyCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminDepositMoney(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminDepositMoneyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminDepositMoney>>>
    export type PostApiAdminDepositMoneyMutationBody = DepositMoneyCommand
    export type PostApiAdminDepositMoneyMutationError = unknown

    export const usePostApiAdminDepositMoney = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminDepositMoney>>, TError,{data: DepositMoneyCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminDepositMoney>>,
        TError,
        {data: DepositMoneyCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminDepositMoneyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminCreateWallet = (
    createWalletCommand: CreateWalletCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/createWallet`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createWalletCommand
    },
      );
    }
  


export const getPostApiAdminCreateWalletMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreateWallet>>, TError,{data: CreateWalletCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreateWallet>>, TError,{data: CreateWalletCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminCreateWallet>>, {data: CreateWalletCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminCreateWallet(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminCreateWalletMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminCreateWallet>>>
    export type PostApiAdminCreateWalletMutationBody = CreateWalletCommand
    export type PostApiAdminCreateWalletMutationError = unknown

    export const usePostApiAdminCreateWallet = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminCreateWallet>>, TError,{data: CreateWalletCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminCreateWallet>>,
        TError,
        {data: CreateWalletCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminCreateWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminGetAllOperationsList = (
    getAllOperationsListQuery: GetAllOperationsListQuery,
 ) => {
      
      
      return apiClient<GetAllOperationsListResponse>(
      {url: `/api/Admin/getAllOperationsList`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getAllOperationsListQuery
    },
      );
    }
  


export const getPostApiAdminGetAllOperationsListMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>, TError,{data: GetAllOperationsListQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>, TError,{data: GetAllOperationsListQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>, {data: GetAllOperationsListQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminGetAllOperationsList(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminGetAllOperationsListMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>>
    export type PostApiAdminGetAllOperationsListMutationBody = GetAllOperationsListQuery
    export type PostApiAdminGetAllOperationsListMutationError = unknown

    export const usePostApiAdminGetAllOperationsList = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>, TError,{data: GetAllOperationsListQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminGetAllOperationsList>>,
        TError,
        {data: GetAllOperationsListQuery},
        TContext
      > => {

      const mutationOptions = getPostApiAdminGetAllOperationsListMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminGetExchangeCommissionsStatistic = (
    getExchangeCommissionsStatisticsQuery: GetExchangeCommissionsStatisticsQuery,
 ) => {
      
      
      return apiClient<GetExchangeCommissionsStatisticResponse>(
      {url: `/api/Admin/getExchangeCommissionsStatistic`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getExchangeCommissionsStatisticsQuery
    },
      );
    }
  


export const getPostApiAdminGetExchangeCommissionsStatisticMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>, TError,{data: GetExchangeCommissionsStatisticsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>, TError,{data: GetExchangeCommissionsStatisticsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>, {data: GetExchangeCommissionsStatisticsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminGetExchangeCommissionsStatistic(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminGetExchangeCommissionsStatisticMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>>
    export type PostApiAdminGetExchangeCommissionsStatisticMutationBody = GetExchangeCommissionsStatisticsQuery
    export type PostApiAdminGetExchangeCommissionsStatisticMutationError = unknown

    export const usePostApiAdminGetExchangeCommissionsStatistic = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>, TError,{data: GetExchangeCommissionsStatisticsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminGetExchangeCommissionsStatistic>>,
        TError,
        {data: GetExchangeCommissionsStatisticsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiAdminGetExchangeCommissionsStatisticMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiAdminGetFullStatistic = (
    
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetFullStatisticResponse>(
      {url: `/api/Admin/getFullStatistic`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiAdminGetFullStatisticQueryKey = () => {
    return [`/api/Admin/getFullStatistic`] as const;
    }

    
export const getGetApiAdminGetFullStatisticQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminGetFullStatisticQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>> = ({ signal }) => getApiAdminGetFullStatistic(signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAdminGetFullStatisticQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>>
export type GetApiAdminGetFullStatisticQueryError = unknown



export function useGetApiAdminGetFullStatistic<TData = Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGetFullStatistic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiAdminGetFullStatisticQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiAdminAccrualOfInterest = (
    accrualOfInterestCommand: AccrualOfInterestCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/accrualOfInterest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accrualOfInterestCommand
    },
      );
    }
  


export const getPostApiAdminAccrualOfInterestMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>, TError,{data: AccrualOfInterestCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>, TError,{data: AccrualOfInterestCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>, {data: AccrualOfInterestCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminAccrualOfInterest(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAccrualOfInterestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>>
    export type PostApiAdminAccrualOfInterestMutationBody = AccrualOfInterestCommand
    export type PostApiAdminAccrualOfInterestMutationError = unknown

    export const usePostApiAdminAccrualOfInterest = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>, TError,{data: AccrualOfInterestCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAccrualOfInterest>>,
        TError,
        {data: AccrualOfInterestCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAccrualOfInterestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminChangeExchangeCommission = (
    changeExchangeCommissionCommand: ChangeExchangeCommissionCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/changeExchangeCommission`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeExchangeCommissionCommand
    },
      );
    }
  


export const getPostApiAdminChangeExchangeCommissionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>, TError,{data: ChangeExchangeCommissionCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>, TError,{data: ChangeExchangeCommissionCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>, {data: ChangeExchangeCommissionCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminChangeExchangeCommission(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminChangeExchangeCommissionMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>>
    export type PostApiAdminChangeExchangeCommissionMutationBody = ChangeExchangeCommissionCommand
    export type PostApiAdminChangeExchangeCommissionMutationError = unknown

    export const usePostApiAdminChangeExchangeCommission = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>, TError,{data: ChangeExchangeCommissionCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminChangeExchangeCommission>>,
        TError,
        {data: ChangeExchangeCommissionCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminChangeExchangeCommissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminAddDepositWallets = (
    addDepositWalletsCommand: AddDepositWalletsCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/addDepositWallets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addDepositWalletsCommand
    },
      );
    }
  


export const getPostApiAdminAddDepositWalletsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>, TError,{data: AddDepositWalletsCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>, TError,{data: AddDepositWalletsCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>, {data: AddDepositWalletsCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminAddDepositWallets(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminAddDepositWalletsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>>
    export type PostApiAdminAddDepositWalletsMutationBody = AddDepositWalletsCommand
    export type PostApiAdminAddDepositWalletsMutationError = unknown

    export const usePostApiAdminAddDepositWallets = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>, TError,{data: AddDepositWalletsCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminAddDepositWallets>>,
        TError,
        {data: AddDepositWalletsCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminAddDepositWalletsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminRemoveDepositWallet = (
    removeDepositWalletCommand: RemoveDepositWalletCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/removeDepositWallet`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: removeDepositWalletCommand
    },
      );
    }
  


export const getPostApiAdminRemoveDepositWalletMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>, TError,{data: RemoveDepositWalletCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>, TError,{data: RemoveDepositWalletCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>, {data: RemoveDepositWalletCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminRemoveDepositWallet(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminRemoveDepositWalletMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>>
    export type PostApiAdminRemoveDepositWalletMutationBody = RemoveDepositWalletCommand
    export type PostApiAdminRemoveDepositWalletMutationError = unknown

    export const usePostApiAdminRemoveDepositWallet = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>, TError,{data: RemoveDepositWalletCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminRemoveDepositWallet>>,
        TError,
        {data: RemoveDepositWalletCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAdminRemoveDepositWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminGetAllDepositWallets = (
    getAllDepositWalletsQuery: GetAllDepositWalletsQuery,
 ) => {
      
      
      return apiClient<GetAllDepositWalletsResponse>(
      {url: `/api/Admin/getAllDepositWallets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getAllDepositWalletsQuery
    },
      );
    }
  


export const getPostApiAdminGetAllDepositWalletsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>, TError,{data: GetAllDepositWalletsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>, TError,{data: GetAllDepositWalletsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>, {data: GetAllDepositWalletsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminGetAllDepositWallets(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminGetAllDepositWalletsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>>
    export type PostApiAdminGetAllDepositWalletsMutationBody = GetAllDepositWalletsQuery
    export type PostApiAdminGetAllDepositWalletsMutationError = unknown

    export const usePostApiAdminGetAllDepositWallets = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>, TError,{data: GetAllDepositWalletsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminGetAllDepositWallets>>,
        TError,
        {data: GetAllDepositWalletsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiAdminGetAllDepositWalletsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminUpdateDepositWalletOwner = (
    updateDepositWalletOwnerQuery: UpdateDepositWalletOwnerQuery,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/updateDepositWalletOwner`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateDepositWalletOwnerQuery
    },
      );
    }
  


export const getPostApiAdminUpdateDepositWalletOwnerMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>, TError,{data: UpdateDepositWalletOwnerQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>, TError,{data: UpdateDepositWalletOwnerQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>, {data: UpdateDepositWalletOwnerQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminUpdateDepositWalletOwner(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminUpdateDepositWalletOwnerMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>>
    export type PostApiAdminUpdateDepositWalletOwnerMutationBody = UpdateDepositWalletOwnerQuery
    export type PostApiAdminUpdateDepositWalletOwnerMutationError = unknown

    export const usePostApiAdminUpdateDepositWalletOwner = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>, TError,{data: UpdateDepositWalletOwnerQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminUpdateDepositWalletOwner>>,
        TError,
        {data: UpdateDepositWalletOwnerQuery},
        TContext
      > => {

      const mutationOptions = getPostApiAdminUpdateDepositWalletOwnerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAdminUpdateDepositWallet = (
    updateDepositWalletQuery: UpdateDepositWalletQuery,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Admin/updateDepositWallet`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateDepositWalletQuery
    },
      );
    }
  


export const getPostApiAdminUpdateDepositWalletMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>, TError,{data: UpdateDepositWalletQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>, TError,{data: UpdateDepositWalletQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>, {data: UpdateDepositWalletQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiAdminUpdateDepositWallet(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAdminUpdateDepositWalletMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>>
    export type PostApiAdminUpdateDepositWalletMutationBody = UpdateDepositWalletQuery
    export type PostApiAdminUpdateDepositWalletMutationError = unknown

    export const usePostApiAdminUpdateDepositWallet = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>, TError,{data: UpdateDepositWalletQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAdminUpdateDepositWallet>>,
        TError,
        {data: UpdateDepositWalletQuery},
        TContext
      > => {

      const mutationOptions = getPostApiAdminUpdateDepositWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthSignIn = (
    signInCommand: SignInCommand,
 ) => {
      
      
      return apiClient<AuthResponse>(
      {url: `/api/Auth/signIn`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signInCommand
    },
      );
    }
  


export const getPostApiAuthSignInMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignIn>>, TError,{data: SignInCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignIn>>, TError,{data: SignInCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthSignIn>>, {data: SignInCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthSignIn(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthSignInMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthSignIn>>>
    export type PostApiAuthSignInMutationBody = SignInCommand
    export type PostApiAuthSignInMutationError = unknown

    export const usePostApiAuthSignIn = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignIn>>, TError,{data: SignInCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthSignIn>>,
        TError,
        {data: SignInCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthSignInMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthRefreshToken = (
    refreshTokenCommand: RefreshTokenCommand,
 ) => {
      
      
      return apiClient<AuthResponse>(
      {url: `/api/Auth/refreshToken`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: refreshTokenCommand
    },
      );
    }
  


export const getPostApiAuthRefreshTokenMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthRefreshToken>>, TError,{data: RefreshTokenCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthRefreshToken>>, TError,{data: RefreshTokenCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthRefreshToken>>, {data: RefreshTokenCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthRefreshToken(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthRefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthRefreshToken>>>
    export type PostApiAuthRefreshTokenMutationBody = RefreshTokenCommand
    export type PostApiAuthRefreshTokenMutationError = unknown

    export const usePostApiAuthRefreshToken = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthRefreshToken>>, TError,{data: RefreshTokenCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthRefreshToken>>,
        TError,
        {data: RefreshTokenCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthRefreshTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthSignUp = (
    signUpCommand: SignUpCommand,
 ) => {
      
      
      return apiClient<AuthResponse>(
      {url: `/api/Auth/signUp`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signUpCommand
    },
      );
    }
  


export const getPostApiAuthSignUpMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignUp>>, TError,{data: SignUpCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignUp>>, TError,{data: SignUpCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthSignUp>>, {data: SignUpCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthSignUp(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthSignUpMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthSignUp>>>
    export type PostApiAuthSignUpMutationBody = SignUpCommand
    export type PostApiAuthSignUpMutationError = unknown

    export const usePostApiAuthSignUp = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthSignUp>>, TError,{data: SignUpCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthSignUp>>,
        TError,
        {data: SignUpCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthSignUpMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthResetPassword = (
    resetPasswordCommand: ResetPasswordCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Auth/resetPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordCommand
    },
      );
    }
  


export const getPostApiAuthResetPasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: ResetPasswordCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: ResetPasswordCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthResetPassword>>, {data: ResetPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthResetPassword(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthResetPassword>>>
    export type PostApiAuthResetPasswordMutationBody = ResetPasswordCommand
    export type PostApiAuthResetPasswordMutationError = unknown

    export const usePostApiAuthResetPassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthResetPassword>>, TError,{data: ResetPasswordCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthResetPassword>>,
        TError,
        {data: ResetPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthForgotPassword = (
    forgotPasswordCommand: ForgotPasswordCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Auth/forgotPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordCommand
    },
      );
    }
  


export const getPostApiAuthForgotPasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgotPassword>>, TError,{data: ForgotPasswordCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgotPassword>>, TError,{data: ForgotPasswordCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthForgotPassword>>, {data: ForgotPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthForgotPassword(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthForgotPassword>>>
    export type PostApiAuthForgotPasswordMutationBody = ForgotPasswordCommand
    export type PostApiAuthForgotPasswordMutationError = unknown

    export const usePostApiAuthForgotPassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthForgotPassword>>, TError,{data: ForgotPasswordCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthForgotPassword>>,
        TError,
        {data: ForgotPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthEnableTwoFactor = (
    
 ) => {
      
      
      return apiClient<string>(
      {url: `/api/Auth/enableTwoFactor`, method: 'POST'
    },
      );
    }
  


export const getPostApiAuthEnableTwoFactorMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>, void> = () => {
          

          return  postApiAuthEnableTwoFactor()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthEnableTwoFactorMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>>
    
    export type PostApiAuthEnableTwoFactorMutationError = unknown

    export const usePostApiAuthEnableTwoFactor = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthEnableTwoFactor>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostApiAuthEnableTwoFactorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthDisableTwoFactor = (
    disableTwoFactorCommand: DisableTwoFactorCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Auth/disableTwoFactor`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: disableTwoFactorCommand
    },
      );
    }
  


export const getPostApiAuthDisableTwoFactorMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>, TError,{data: DisableTwoFactorCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>, TError,{data: DisableTwoFactorCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>, {data: DisableTwoFactorCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthDisableTwoFactor(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthDisableTwoFactorMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>>
    export type PostApiAuthDisableTwoFactorMutationBody = DisableTwoFactorCommand
    export type PostApiAuthDisableTwoFactorMutationError = unknown

    export const usePostApiAuthDisableTwoFactor = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>, TError,{data: DisableTwoFactorCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthDisableTwoFactor>>,
        TError,
        {data: DisableTwoFactorCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthDisableTwoFactorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiAuthConfirmTwoFactor = (
    confirmTwoFactorCommand: ConfirmTwoFactorCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Auth/confirmTwoFactor`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: confirmTwoFactorCommand
    },
      );
    }
  


export const getPostApiAuthConfirmTwoFactorMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>, TError,{data: ConfirmTwoFactorCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>, TError,{data: ConfirmTwoFactorCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>, {data: ConfirmTwoFactorCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthConfirmTwoFactor(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthConfirmTwoFactorMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>>
    export type PostApiAuthConfirmTwoFactorMutationBody = ConfirmTwoFactorCommand
    export type PostApiAuthConfirmTwoFactorMutationError = unknown

    export const usePostApiAuthConfirmTwoFactor = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>, TError,{data: ConfirmTwoFactorCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthConfirmTwoFactor>>,
        TError,
        {data: ConfirmTwoFactorCommand},
        TContext
      > => {

      const mutationOptions = getPostApiAuthConfirmTwoFactorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiCurrenciesGetCurrencies = (
    
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetCurrenciesResponse>(
      {url: `/api/Currencies/getCurrencies`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiCurrenciesGetCurrenciesQueryKey = () => {
    return [`/api/Currencies/getCurrencies`] as const;
    }

    
export const getGetApiCurrenciesGetCurrenciesQueryOptions = <TData = Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiCurrenciesGetCurrenciesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>> = ({ signal }) => getApiCurrenciesGetCurrencies(signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiCurrenciesGetCurrenciesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>>
export type GetApiCurrenciesGetCurrenciesQueryError = unknown



export function useGetApiCurrenciesGetCurrencies<TData = Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiCurrenciesGetCurrencies>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiCurrenciesGetCurrenciesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiCurrenciesGetCurrencyNetworkInfo = (
    getCurrencyNetworkInfoQuery: GetCurrencyNetworkInfoQuery,
 ) => {
      
      
      return apiClient<CurrencyNetworkInfoDto[]>(
      {url: `/api/Currencies/getCurrencyNetworkInfo`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getCurrencyNetworkInfoQuery
    },
      );
    }
  


export const getPostApiCurrenciesGetCurrencyNetworkInfoMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>, TError,{data: GetCurrencyNetworkInfoQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>, TError,{data: GetCurrencyNetworkInfoQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>, {data: GetCurrencyNetworkInfoQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesGetCurrencyNetworkInfo(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesGetCurrencyNetworkInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>>
    export type PostApiCurrenciesGetCurrencyNetworkInfoMutationBody = GetCurrencyNetworkInfoQuery
    export type PostApiCurrenciesGetCurrencyNetworkInfoMutationError = unknown

    export const usePostApiCurrenciesGetCurrencyNetworkInfo = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>, TError,{data: GetCurrencyNetworkInfoQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesGetCurrencyNetworkInfo>>,
        TError,
        {data: GetCurrencyNetworkInfoQuery},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesGetCurrencyNetworkInfoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCurrenciesCreateCurrency = (
    createCurrencyCommand: CreateCurrencyCommand,
 ) => {
      
      
      return apiClient<string>(
      {url: `/api/Currencies/createCurrency`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCurrencyCommand
    },
      );
    }
  


export const getPostApiCurrenciesCreateCurrencyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>, TError,{data: CreateCurrencyCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>, TError,{data: CreateCurrencyCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>, {data: CreateCurrencyCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesCreateCurrency(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesCreateCurrencyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>>
    export type PostApiCurrenciesCreateCurrencyMutationBody = CreateCurrencyCommand
    export type PostApiCurrenciesCreateCurrencyMutationError = unknown

    export const usePostApiCurrenciesCreateCurrency = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>, TError,{data: CreateCurrencyCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesCreateCurrency>>,
        TError,
        {data: CreateCurrencyCommand},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesCreateCurrencyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCurrenciesAddOrUpdateCurrencyNetworkInfo = (
    addOrUpdateCurrencyNetworkInfoCommand: AddOrUpdateCurrencyNetworkInfoCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Currencies/addOrUpdateCurrencyNetworkInfo`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addOrUpdateCurrencyNetworkInfoCommand
    },
      );
    }
  


export const getPostApiCurrenciesAddOrUpdateCurrencyNetworkInfoMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>, TError,{data: AddOrUpdateCurrencyNetworkInfoCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>, TError,{data: AddOrUpdateCurrencyNetworkInfoCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>, {data: AddOrUpdateCurrencyNetworkInfoCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesAddOrUpdateCurrencyNetworkInfo(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesAddOrUpdateCurrencyNetworkInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>>
    export type PostApiCurrenciesAddOrUpdateCurrencyNetworkInfoMutationBody = AddOrUpdateCurrencyNetworkInfoCommand
    export type PostApiCurrenciesAddOrUpdateCurrencyNetworkInfoMutationError = unknown

    export const usePostApiCurrenciesAddOrUpdateCurrencyNetworkInfo = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>, TError,{data: AddOrUpdateCurrencyNetworkInfoCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesAddOrUpdateCurrencyNetworkInfo>>,
        TError,
        {data: AddOrUpdateCurrencyNetworkInfoCommand},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesAddOrUpdateCurrencyNetworkInfoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCurrenciesUpdateCurrency = (
    updateCurrencyCommand: UpdateCurrencyCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Currencies/updateCurrency`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateCurrencyCommand
    },
      );
    }
  


export const getPostApiCurrenciesUpdateCurrencyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>, TError,{data: UpdateCurrencyCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>, TError,{data: UpdateCurrencyCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>, {data: UpdateCurrencyCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesUpdateCurrency(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesUpdateCurrencyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>>
    export type PostApiCurrenciesUpdateCurrencyMutationBody = UpdateCurrencyCommand
    export type PostApiCurrenciesUpdateCurrencyMutationError = unknown

    export const usePostApiCurrenciesUpdateCurrency = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>, TError,{data: UpdateCurrencyCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesUpdateCurrency>>,
        TError,
        {data: UpdateCurrencyCommand},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesUpdateCurrencyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCurrenciesGetExchangeRate = (
    getExchangeRateQuery: GetExchangeRateQuery,
 ) => {
      
      
      return apiClient<ExchangeRateDto>(
      {url: `/api/Currencies/getExchangeRate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getExchangeRateQuery
    },
      );
    }
  


export const getPostApiCurrenciesGetExchangeRateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>, TError,{data: GetExchangeRateQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>, TError,{data: GetExchangeRateQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>, {data: GetExchangeRateQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesGetExchangeRate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesGetExchangeRateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>>
    export type PostApiCurrenciesGetExchangeRateMutationBody = GetExchangeRateQuery
    export type PostApiCurrenciesGetExchangeRateMutationError = unknown

    export const usePostApiCurrenciesGetExchangeRate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>, TError,{data: GetExchangeRateQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesGetExchangeRate>>,
        TError,
        {data: GetExchangeRateQuery},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesGetExchangeRateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiCurrenciesExchangeCurrencies = (
    exchangeCurrenciesCommand: ExchangeCurrenciesCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Currencies/exchangeCurrencies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: exchangeCurrenciesCommand
    },
      );
    }
  


export const getPostApiCurrenciesExchangeCurrenciesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>, TError,{data: ExchangeCurrenciesCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>, TError,{data: ExchangeCurrenciesCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>, {data: ExchangeCurrenciesCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiCurrenciesExchangeCurrencies(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiCurrenciesExchangeCurrenciesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>>
    export type PostApiCurrenciesExchangeCurrenciesMutationBody = ExchangeCurrenciesCommand
    export type PostApiCurrenciesExchangeCurrenciesMutationError = unknown

    export const usePostApiCurrenciesExchangeCurrencies = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>, TError,{data: ExchangeCurrenciesCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiCurrenciesExchangeCurrencies>>,
        TError,
        {data: ExchangeCurrenciesCommand},
        TContext
      > => {

      const mutationOptions = getPostApiCurrenciesExchangeCurrenciesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiFileId = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return apiClient<FileDto>(
      {url: `/api/File/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiFileIdQueryKey = (id: string,) => {
    return [`/api/File/${id}`] as const;
    }

    
export const getGetApiFileIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiFileId>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiFileId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiFileIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiFileId>>> = ({ signal }) => getApiFileId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id),  retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiFileId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiFileIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileId>>>
export type GetApiFileIdQueryError = unknown



export function useGetApiFileId<TData = Awaited<ReturnType<typeof getApiFileId>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiFileId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiFileIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiFileFiles = (
    getFilesQuery: GetFilesQuery,
 ) => {
      
      
      return apiClient<GetFilesResponse>(
      {url: `/api/File/files`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getFilesQuery
    },
      );
    }
  


export const getPostApiFileFilesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFileFiles>>, TError,{data: GetFilesQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiFileFiles>>, TError,{data: GetFilesQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiFileFiles>>, {data: GetFilesQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiFileFiles(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiFileFilesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiFileFiles>>>
    export type PostApiFileFilesMutationBody = GetFilesQuery
    export type PostApiFileFilesMutationError = unknown

    export const usePostApiFileFiles = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFileFiles>>, TError,{data: GetFilesQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiFileFiles>>,
        TError,
        {data: GetFilesQuery},
        TContext
      > => {

      const mutationOptions = getPostApiFileFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiFileUpload = (
    postApiFileUploadBody: PostApiFileUploadBody,
 ) => {
      
      const formData = new FormData();
if(postApiFileUploadBody.Files !== undefined) {
 postApiFileUploadBody.Files.forEach(value => formData.append('Files', value));
 }
if(postApiFileUploadBody.Type !== undefined) {
 formData.append('Type', postApiFileUploadBody.Type)
 }

      return apiClient<string[]>(
      {url: `/api/File/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getPostApiFileUploadMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFileUpload>>, TError,{data: PostApiFileUploadBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiFileUpload>>, TError,{data: PostApiFileUploadBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiFileUpload>>, {data: PostApiFileUploadBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiFileUpload(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiFileUploadMutationResult = NonNullable<Awaited<ReturnType<typeof postApiFileUpload>>>
    export type PostApiFileUploadMutationBody = PostApiFileUploadBody
    export type PostApiFileUploadMutationError = unknown

    export const usePostApiFileUpload = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiFileUpload>>, TError,{data: PostApiFileUploadBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiFileUpload>>,
        TError,
        {data: PostApiFileUploadBody},
        TContext
      > => {

      const mutationOptions = getPostApiFileUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiInvestPoolGetAllInvestPools = (
    params?: GetApiInvestPoolGetAllInvestPoolsParams,
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetInvestPoolsResponse>(
      {url: `/api/InvestPool/getAllInvestPools`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiInvestPoolGetAllInvestPoolsQueryKey = (params?: GetApiInvestPoolGetAllInvestPoolsParams,) => {
    return [`/api/InvestPool/getAllInvestPools`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiInvestPoolGetAllInvestPoolsQueryOptions = <TData = Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>, TError = unknown>(params?: GetApiInvestPoolGetAllInvestPoolsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiInvestPoolGetAllInvestPoolsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>> = ({ signal }) => getApiInvestPoolGetAllInvestPools(params, signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiInvestPoolGetAllInvestPoolsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>>
export type GetApiInvestPoolGetAllInvestPoolsQueryError = unknown



export function useGetApiInvestPoolGetAllInvestPools<TData = Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>, TError = unknown>(
 params?: GetApiInvestPoolGetAllInvestPoolsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiInvestPoolGetAllInvestPools>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiInvestPoolGetAllInvestPoolsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiInvestPoolGetInvestPoolDetails = (
    getInvestPoolQuery: GetInvestPoolQuery,
 ) => {
      
      
      return apiClient<InvestPoolDto>(
      {url: `/api/InvestPool/getInvestPoolDetails`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getInvestPoolQuery
    },
      );
    }
  


export const getPostApiInvestPoolGetInvestPoolDetailsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>, TError,{data: GetInvestPoolQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>, TError,{data: GetInvestPoolQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>, {data: GetInvestPoolQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolGetInvestPoolDetails(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolGetInvestPoolDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>>
    export type PostApiInvestPoolGetInvestPoolDetailsMutationBody = GetInvestPoolQuery
    export type PostApiInvestPoolGetInvestPoolDetailsMutationError = unknown

    export const usePostApiInvestPoolGetInvestPoolDetails = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>, TError,{data: GetInvestPoolQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolGetInvestPoolDetails>>,
        TError,
        {data: GetInvestPoolQuery},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolGetInvestPoolDetailsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolCreateInvestPool = (
    postApiInvestPoolCreateInvestPoolBody: PostApiInvestPoolCreateInvestPoolBody,
 ) => {
      
      const formData = new FormData();
if(postApiInvestPoolCreateInvestPoolBody.Name !== undefined) {
 formData.append('Name', postApiInvestPoolCreateInvestPoolBody.Name)
 }
if(postApiInvestPoolCreateInvestPoolBody.Description !== undefined) {
 formData.append('Description', postApiInvestPoolCreateInvestPoolBody.Description)
 }
if(postApiInvestPoolCreateInvestPoolBody.Logo !== undefined) {
 formData.append('Logo', postApiInvestPoolCreateInvestPoolBody.Logo)
 }
if(postApiInvestPoolCreateInvestPoolBody.Image !== undefined) {
 formData.append('Image', postApiInvestPoolCreateInvestPoolBody.Image)
 }
if(postApiInvestPoolCreateInvestPoolBody.Tags !== undefined) {
 postApiInvestPoolCreateInvestPoolBody.Tags.forEach(value => formData.append('Tags', value));
 }
if(postApiInvestPoolCreateInvestPoolBody.AvailableCurrenciesIds !== undefined) {
 postApiInvestPoolCreateInvestPoolBody.AvailableCurrenciesIds.forEach(value => formData.append('AvailableCurrenciesIds', value));
 }
if(postApiInvestPoolCreateInvestPoolBody.Period !== undefined) {
 formData.append('Period', postApiInvestPoolCreateInvestPoolBody.Period)
 }
if(postApiInvestPoolCreateInvestPoolBody.Term !== undefined) {
 formData.append('Term', postApiInvestPoolCreateInvestPoolBody.Term)
 }
if(postApiInvestPoolCreateInvestPoolBody.EnableAccrualInterestFromReferral !== undefined) {
 formData.append('EnableAccrualInterestFromReferral', postApiInvestPoolCreateInvestPoolBody.EnableAccrualInterestFromReferral.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.HoldInDays !== undefined) {
 formData.append('HoldInDays', postApiInvestPoolCreateInvestPoolBody.HoldInDays.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.FirstApr !== undefined) {
 formData.append('FirstApr', postApiInvestPoolCreateInvestPoolBody.FirstApr.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.SecondApr !== undefined) {
 formData.append('SecondApr', postApiInvestPoolCreateInvestPoolBody.SecondApr.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.UserLimit !== undefined) {
 formData.append('UserLimit', postApiInvestPoolCreateInvestPoolBody.UserLimit.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.TotalLimit !== undefined) {
 formData.append('TotalLimit', postApiInvestPoolCreateInvestPoolBody.TotalLimit.toString())
 }
if(postApiInvestPoolCreateInvestPoolBody.FairAccrual !== undefined) {
 formData.append('FairAccrual', postApiInvestPoolCreateInvestPoolBody.FairAccrual.toString())
 }

      return apiClient<string>(
      {url: `/api/InvestPool/createInvestPool`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getPostApiInvestPoolCreateInvestPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>, TError,{data: PostApiInvestPoolCreateInvestPoolBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>, TError,{data: PostApiInvestPoolCreateInvestPoolBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>, {data: PostApiInvestPoolCreateInvestPoolBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolCreateInvestPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolCreateInvestPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>>
    export type PostApiInvestPoolCreateInvestPoolMutationBody = PostApiInvestPoolCreateInvestPoolBody
    export type PostApiInvestPoolCreateInvestPoolMutationError = unknown

    export const usePostApiInvestPoolCreateInvestPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>, TError,{data: PostApiInvestPoolCreateInvestPoolBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolCreateInvestPool>>,
        TError,
        {data: PostApiInvestPoolCreateInvestPoolBody},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolCreateInvestPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolUpdateInvestPool = (
    updateInvestPoolCommand: UpdateInvestPoolCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/InvestPool/updateInvestPool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateInvestPoolCommand
    },
      );
    }
  


export const getPostApiInvestPoolUpdateInvestPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>, TError,{data: UpdateInvestPoolCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>, TError,{data: UpdateInvestPoolCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>, {data: UpdateInvestPoolCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolUpdateInvestPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolUpdateInvestPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>>
    export type PostApiInvestPoolUpdateInvestPoolMutationBody = UpdateInvestPoolCommand
    export type PostApiInvestPoolUpdateInvestPoolMutationError = unknown

    export const usePostApiInvestPoolUpdateInvestPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>, TError,{data: UpdateInvestPoolCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolUpdateInvestPool>>,
        TError,
        {data: UpdateInvestPoolCommand},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolUpdateInvestPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolCloseInvestPool = (
    closeInvestPoolCommand: CloseInvestPoolCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/InvestPool/closeInvestPool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: closeInvestPoolCommand
    },
      );
    }
  


export const getPostApiInvestPoolCloseInvestPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>, TError,{data: CloseInvestPoolCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>, TError,{data: CloseInvestPoolCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>, {data: CloseInvestPoolCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolCloseInvestPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolCloseInvestPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>>
    export type PostApiInvestPoolCloseInvestPoolMutationBody = CloseInvestPoolCommand
    export type PostApiInvestPoolCloseInvestPoolMutationError = unknown

    export const usePostApiInvestPoolCloseInvestPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>, TError,{data: CloseInvestPoolCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolCloseInvestPool>>,
        TError,
        {data: CloseInvestPoolCommand},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolCloseInvestPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolInvestInInvestmentPool = (
    investInInvestmentPoolCommand: InvestInInvestmentPoolCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/InvestPool/investInInvestmentPool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: investInInvestmentPoolCommand
    },
      );
    }
  


export const getPostApiInvestPoolInvestInInvestmentPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>, TError,{data: InvestInInvestmentPoolCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>, TError,{data: InvestInInvestmentPoolCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>, {data: InvestInInvestmentPoolCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolInvestInInvestmentPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolInvestInInvestmentPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>>
    export type PostApiInvestPoolInvestInInvestmentPoolMutationBody = InvestInInvestmentPoolCommand
    export type PostApiInvestPoolInvestInInvestmentPoolMutationError = unknown

    export const usePostApiInvestPoolInvestInInvestmentPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>, TError,{data: InvestInInvestmentPoolCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolInvestInInvestmentPool>>,
        TError,
        {data: InvestInInvestmentPoolCommand},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolInvestInInvestmentPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolWithdrawalFromInvestmentPool = (
    withdrawalFromInvestmentPoolCommand: WithdrawalFromInvestmentPoolCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/InvestPool/withdrawalFromInvestmentPool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: withdrawalFromInvestmentPoolCommand
    },
      );
    }
  


export const getPostApiInvestPoolWithdrawalFromInvestmentPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>, TError,{data: WithdrawalFromInvestmentPoolCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>, TError,{data: WithdrawalFromInvestmentPoolCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>, {data: WithdrawalFromInvestmentPoolCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolWithdrawalFromInvestmentPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolWithdrawalFromInvestmentPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>>
    export type PostApiInvestPoolWithdrawalFromInvestmentPoolMutationBody = WithdrawalFromInvestmentPoolCommand
    export type PostApiInvestPoolWithdrawalFromInvestmentPoolMutationError = unknown

    export const usePostApiInvestPoolWithdrawalFromInvestmentPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>, TError,{data: WithdrawalFromInvestmentPoolCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolWithdrawalFromInvestmentPool>>,
        TError,
        {data: WithdrawalFromInvestmentPoolCommand},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolWithdrawalFromInvestmentPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiInvestPoolAccrualOfInterestInInvestPool = (
    accrualOfInterestInInvestPoolCommand: AccrualOfInterestInInvestPoolCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/InvestPool/accrualOfInterestInInvestPool`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accrualOfInterestInInvestPoolCommand
    },
      );
    }
  


export const getPostApiInvestPoolAccrualOfInterestInInvestPoolMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>, TError,{data: AccrualOfInterestInInvestPoolCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>, TError,{data: AccrualOfInterestInInvestPoolCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>, {data: AccrualOfInterestInInvestPoolCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiInvestPoolAccrualOfInterestInInvestPool(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiInvestPoolAccrualOfInterestInInvestPoolMutationResult = NonNullable<Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>>
    export type PostApiInvestPoolAccrualOfInterestInInvestPoolMutationBody = AccrualOfInterestInInvestPoolCommand
    export type PostApiInvestPoolAccrualOfInterestInInvestPoolMutationError = unknown

    export const usePostApiInvestPoolAccrualOfInterestInInvestPool = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>, TError,{data: AccrualOfInterestInInvestPoolCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiInvestPoolAccrualOfInterestInInvestPool>>,
        TError,
        {data: AccrualOfInterestInInvestPoolCommand},
        TContext
      > => {

      const mutationOptions = getPostApiInvestPoolAccrualOfInterestInInvestPoolMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiNetworkGetNetworksList = (
    
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetNetworksListResponse>(
      {url: `/api/Network/getNetworksList`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiNetworkGetNetworksListQueryKey = () => {
    return [`/api/Network/getNetworksList`] as const;
    }

    
export const getGetApiNetworkGetNetworksListQueryOptions = <TData = Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNetworkGetNetworksListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>> = ({ signal }) => getApiNetworkGetNetworksList(signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNetworkGetNetworksListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>>
export type GetApiNetworkGetNetworksListQueryError = unknown



export function useGetApiNetworkGetNetworksList<TData = Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetNetworksList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNetworkGetNetworksListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiNetworkGetDepositWallet = (
    params?: GetApiNetworkGetDepositWalletParams,
 signal?: AbortSignal
) => {
      
      
      return apiClient<DepositWalletDto>(
      {url: `/api/Network/getDepositWallet`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiNetworkGetDepositWalletQueryKey = (params?: GetApiNetworkGetDepositWalletParams,) => {
    return [`/api/Network/getDepositWallet`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiNetworkGetDepositWalletQueryOptions = <TData = Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>, TError = unknown>(params?: GetApiNetworkGetDepositWalletParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiNetworkGetDepositWalletQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>> = ({ signal }) => getApiNetworkGetDepositWallet(params, signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiNetworkGetDepositWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>>
export type GetApiNetworkGetDepositWalletQueryError = unknown



export function useGetApiNetworkGetDepositWallet<TData = Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>, TError = unknown>(
 params?: GetApiNetworkGetDepositWalletParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiNetworkGetDepositWallet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiNetworkGetDepositWalletQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiNetworkCreateNetwork = (
    createNetworkCommand: CreateNetworkCommand,
 ) => {
      
      
      return apiClient<string>(
      {url: `/api/Network/CreateNetwork`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createNetworkCommand
    },
      );
    }
  


export const getPostApiNetworkCreateNetworkMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>, TError,{data: CreateNetworkCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>, TError,{data: CreateNetworkCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>, {data: CreateNetworkCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiNetworkCreateNetwork(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiNetworkCreateNetworkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>>
    export type PostApiNetworkCreateNetworkMutationBody = CreateNetworkCommand
    export type PostApiNetworkCreateNetworkMutationError = unknown

    export const usePostApiNetworkCreateNetwork = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>, TError,{data: CreateNetworkCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiNetworkCreateNetwork>>,
        TError,
        {data: CreateNetworkCommand},
        TContext
      > => {

      const mutationOptions = getPostApiNetworkCreateNetworkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiNetworkUpdateNetwork = (
    updateNetworkCommand: UpdateNetworkCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Network/UpdateNetwork`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateNetworkCommand
    },
      );
    }
  


export const getPostApiNetworkUpdateNetworkMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>, TError,{data: UpdateNetworkCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>, TError,{data: UpdateNetworkCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>, {data: UpdateNetworkCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiNetworkUpdateNetwork(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiNetworkUpdateNetworkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>>
    export type PostApiNetworkUpdateNetworkMutationBody = UpdateNetworkCommand
    export type PostApiNetworkUpdateNetworkMutationError = unknown

    export const usePostApiNetworkUpdateNetwork = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>, TError,{data: UpdateNetworkCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiNetworkUpdateNetwork>>,
        TError,
        {data: UpdateNetworkCommand},
        TContext
      > => {

      const mutationOptions = getPostApiNetworkUpdateNetworkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiNetworkDeleteNetwork = (
    deleteNetworkCommand: DeleteNetworkCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Network/DeleteNetwork`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deleteNetworkCommand
    },
      );
    }
  


export const getPostApiNetworkDeleteNetworkMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>, TError,{data: DeleteNetworkCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>, TError,{data: DeleteNetworkCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>, {data: DeleteNetworkCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiNetworkDeleteNetwork(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiNetworkDeleteNetworkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>>
    export type PostApiNetworkDeleteNetworkMutationBody = DeleteNetworkCommand
    export type PostApiNetworkDeleteNetworkMutationError = unknown

    export const usePostApiNetworkDeleteNetwork = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>, TError,{data: DeleteNetworkCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiNetworkDeleteNetwork>>,
        TError,
        {data: DeleteNetworkCommand},
        TContext
      > => {

      const mutationOptions = getPostApiNetworkDeleteNetworkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiUserGetAllUsersList = (
    params?: GetApiUserGetAllUsersListParams,
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetUsersListResponse>(
      {url: `/api/User/getAllUsersList`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiUserGetAllUsersListQueryKey = (params?: GetApiUserGetAllUsersListParams,) => {
    return [`/api/User/getAllUsersList`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiUserGetAllUsersListQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserGetAllUsersList>>, TError = unknown>(params?: GetApiUserGetAllUsersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetAllUsersList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUserGetAllUsersListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserGetAllUsersList>>> = ({ signal }) => getApiUserGetAllUsersList(params, signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetAllUsersList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiUserGetAllUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetAllUsersList>>>
export type GetApiUserGetAllUsersListQueryError = unknown



export function useGetApiUserGetAllUsersList<TData = Awaited<ReturnType<typeof getApiUserGetAllUsersList>>, TError = unknown>(
 params?: GetApiUserGetAllUsersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetAllUsersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiUserGetAllUsersListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getApiUserGetUserReferrals = (
    params?: GetApiUserGetUserReferralsParams,
 signal?: AbortSignal
) => {
      
      
      return apiClient<GetUsersReferralListResponse>(
      {url: `/api/User/getUserReferrals`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiUserGetUserReferralsQueryKey = (params?: GetApiUserGetUserReferralsParams,) => {
    return [`/api/User/getUserReferrals`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiUserGetUserReferralsQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserGetUserReferrals>>, TError = unknown>(params?: GetApiUserGetUserReferralsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetUserReferrals>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUserGetUserReferralsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserGetUserReferrals>>> = ({ signal }) => getApiUserGetUserReferrals(params, signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetUserReferrals>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiUserGetUserReferralsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetUserReferrals>>>
export type GetApiUserGetUserReferralsQueryError = unknown



export function useGetApiUserGetUserReferrals<TData = Awaited<ReturnType<typeof getApiUserGetUserReferrals>>, TError = unknown>(
 params?: GetApiUserGetUserReferralsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetUserReferrals>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiUserGetUserReferralsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiUserGetUserDetails = (
    getUserDetailsQuery: GetUserDetailsQuery,
 ) => {
      
      
      return apiClient<UserDetailsDto>(
      {url: `/api/User/getUserDetails`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getUserDetailsQuery
    },
      );
    }
  


export const getPostApiUserGetUserDetailsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserDetails>>, TError,{data: GetUserDetailsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserDetails>>, TError,{data: GetUserDetailsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserGetUserDetails>>, {data: GetUserDetailsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserGetUserDetails(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserGetUserDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserGetUserDetails>>>
    export type PostApiUserGetUserDetailsMutationBody = GetUserDetailsQuery
    export type PostApiUserGetUserDetailsMutationError = unknown

    export const usePostApiUserGetUserDetails = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserDetails>>, TError,{data: GetUserDetailsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserGetUserDetails>>,
        TError,
        {data: GetUserDetailsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiUserGetUserDetailsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getApiUserGetCurrentUser = (
    
 signal?: AbortSignal
) => {
      
      
      return apiClient<CurrentUserDto>(
      {url: `/api/User/getCurrentUser`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiUserGetCurrentUserQueryKey = () => {
    return [`/api/User/getCurrentUser`] as const;
    }

    
export const getGetApiUserGetCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserGetCurrentUser>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetCurrentUser>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUserGetCurrentUserQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserGetCurrentUser>>> = ({ signal }) => getApiUserGetCurrentUser(signal);

      

      

   return  { queryKey, queryFn,   retry: 3, retryDelay: 1000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiUserGetCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetCurrentUser>>>
export type GetApiUserGetCurrentUserQueryError = unknown



export function useGetApiUserGetCurrentUser<TData = Awaited<ReturnType<typeof getApiUserGetCurrentUser>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserGetCurrentUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiUserGetCurrentUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postApiUserUpdateUserInfo = (
    updateUserInfoCommand: UpdateUserInfoCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/User/updateUserInfo`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateUserInfoCommand
    },
      );
    }
  


export const getPostApiUserUpdateUserInfoMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>, TError,{data: UpdateUserInfoCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>, TError,{data: UpdateUserInfoCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>, {data: UpdateUserInfoCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserUpdateUserInfo(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserUpdateUserInfoMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>>
    export type PostApiUserUpdateUserInfoMutationBody = UpdateUserInfoCommand
    export type PostApiUserUpdateUserInfoMutationError = unknown

    export const usePostApiUserUpdateUserInfo = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>, TError,{data: UpdateUserInfoCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserUpdateUserInfo>>,
        TError,
        {data: UpdateUserInfoCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserUpdateUserInfoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserUpdatePassword = (
    updatePasswordCommand: UpdatePasswordCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/User/updatePassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updatePasswordCommand
    },
      );
    }
  


export const getPostApiUserUpdatePasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdatePassword>>, TError,{data: UpdatePasswordCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdatePassword>>, TError,{data: UpdatePasswordCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserUpdatePassword>>, {data: UpdatePasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserUpdatePassword(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserUpdatePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserUpdatePassword>>>
    export type PostApiUserUpdatePasswordMutationBody = UpdatePasswordCommand
    export type PostApiUserUpdatePasswordMutationError = unknown

    export const usePostApiUserUpdatePassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserUpdatePassword>>, TError,{data: UpdatePasswordCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserUpdatePassword>>,
        TError,
        {data: UpdatePasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserUpdatePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserGetGlobalDashboardItems = (
    getGlobalDashboardItemsQuery: GetGlobalDashboardItemsQuery,
 ) => {
      
      
      return apiClient<GetGlobalDashboardItemsResponse>(
      {url: `/api/User/getGlobalDashboardItems`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getGlobalDashboardItemsQuery
    },
      );
    }
  


export const getPostApiUserGetGlobalDashboardItemsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>, TError,{data: GetGlobalDashboardItemsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>, TError,{data: GetGlobalDashboardItemsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>, {data: GetGlobalDashboardItemsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserGetGlobalDashboardItems(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserGetGlobalDashboardItemsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>>
    export type PostApiUserGetGlobalDashboardItemsMutationBody = GetGlobalDashboardItemsQuery
    export type PostApiUserGetGlobalDashboardItemsMutationError = unknown

    export const usePostApiUserGetGlobalDashboardItems = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>, TError,{data: GetGlobalDashboardItemsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserGetGlobalDashboardItems>>,
        TError,
        {data: GetGlobalDashboardItemsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiUserGetGlobalDashboardItemsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserGetDashboardItems = (
    getDashboardItemsQuery: GetDashboardItemsQuery,
 ) => {
      
      
      return apiClient<GetDashboardItemsResponse>(
      {url: `/api/User/getDashboardItems`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getDashboardItemsQuery
    },
      );
    }
  


export const getPostApiUserGetDashboardItemsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetDashboardItems>>, TError,{data: GetDashboardItemsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetDashboardItems>>, TError,{data: GetDashboardItemsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserGetDashboardItems>>, {data: GetDashboardItemsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserGetDashboardItems(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserGetDashboardItemsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserGetDashboardItems>>>
    export type PostApiUserGetDashboardItemsMutationBody = GetDashboardItemsQuery
    export type PostApiUserGetDashboardItemsMutationError = unknown

    export const usePostApiUserGetDashboardItems = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetDashboardItems>>, TError,{data: GetDashboardItemsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserGetDashboardItems>>,
        TError,
        {data: GetDashboardItemsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiUserGetDashboardItemsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserGetAccrualOfInterestDashboardItems = (
    getAccrualOfInterestDashboardItemsQuery: GetAccrualOfInterestDashboardItemsQuery,
 ) => {
      
      
      return apiClient<GetAccrualOfInterestDashboardItemsResponse>(
      {url: `/api/User/getAccrualOfInterestDashboardItems`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getAccrualOfInterestDashboardItemsQuery
    },
      );
    }
  


export const getPostApiUserGetAccrualOfInterestDashboardItemsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>, TError,{data: GetAccrualOfInterestDashboardItemsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>, TError,{data: GetAccrualOfInterestDashboardItemsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>, {data: GetAccrualOfInterestDashboardItemsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserGetAccrualOfInterestDashboardItems(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserGetAccrualOfInterestDashboardItemsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>>
    export type PostApiUserGetAccrualOfInterestDashboardItemsMutationBody = GetAccrualOfInterestDashboardItemsQuery
    export type PostApiUserGetAccrualOfInterestDashboardItemsMutationError = unknown

    export const usePostApiUserGetAccrualOfInterestDashboardItems = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>, TError,{data: GetAccrualOfInterestDashboardItemsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserGetAccrualOfInterestDashboardItems>>,
        TError,
        {data: GetAccrualOfInterestDashboardItemsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiUserGetAccrualOfInterestDashboardItemsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserGetUserOperationsList = (
    getUserOperationsListQuery: GetUserOperationsListQuery,
 ) => {
      
      
      return apiClient<GetUserOperationsListResponse>(
      {url: `/api/User/getUserOperationsList`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getUserOperationsListQuery
    },
      );
    }
  


export const getPostApiUserGetUserOperationsListMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>, TError,{data: GetUserOperationsListQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>, TError,{data: GetUserOperationsListQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>, {data: GetUserOperationsListQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserGetUserOperationsList(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserGetUserOperationsListMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>>
    export type PostApiUserGetUserOperationsListMutationBody = GetUserOperationsListQuery
    export type PostApiUserGetUserOperationsListMutationError = unknown

    export const usePostApiUserGetUserOperationsList = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>, TError,{data: GetUserOperationsListQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserGetUserOperationsList>>,
        TError,
        {data: GetUserOperationsListQuery},
        TContext
      > => {

      const mutationOptions = getPostApiUserGetUserOperationsListMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWalletsCreate = (
    createWalletCommand: CreateWalletCommand,
 ) => {
      
      
      return apiClient<string>(
      {url: `/api/Wallets/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createWalletCommand
    },
      );
    }
  


export const getPostApiWalletsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsCreate>>, TError,{data: CreateWalletCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsCreate>>, TError,{data: CreateWalletCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWalletsCreate>>, {data: CreateWalletCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWalletsCreate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWalletsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWalletsCreate>>>
    export type PostApiWalletsCreateMutationBody = CreateWalletCommand
    export type PostApiWalletsCreateMutationError = unknown

    export const usePostApiWalletsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsCreate>>, TError,{data: CreateWalletCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWalletsCreate>>,
        TError,
        {data: CreateWalletCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWalletsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWalletsUpdate = (
    updateWalletCommand: UpdateWalletCommand,
 ) => {
      
      
      return apiClient<string>(
      {url: `/api/Wallets/update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateWalletCommand
    },
      );
    }
  


export const getPostApiWalletsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsUpdate>>, TError,{data: UpdateWalletCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsUpdate>>, TError,{data: UpdateWalletCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWalletsUpdate>>, {data: UpdateWalletCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWalletsUpdate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWalletsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWalletsUpdate>>>
    export type PostApiWalletsUpdateMutationBody = UpdateWalletCommand
    export type PostApiWalletsUpdateMutationError = unknown

    export const usePostApiWalletsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWalletsUpdate>>, TError,{data: UpdateWalletCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWalletsUpdate>>,
        TError,
        {data: UpdateWalletCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWalletsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWithdrawalCreateWithdrawalRequest = (
    createWithdrawalRequestCommand: CreateWithdrawalRequestCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Withdrawal/createWithdrawalRequest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createWithdrawalRequestCommand
    },
      );
    }
  


export const getPostApiWithdrawalCreateWithdrawalRequestMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>, TError,{data: CreateWithdrawalRequestCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>, TError,{data: CreateWithdrawalRequestCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>, {data: CreateWithdrawalRequestCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWithdrawalCreateWithdrawalRequest(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWithdrawalCreateWithdrawalRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>>
    export type PostApiWithdrawalCreateWithdrawalRequestMutationBody = CreateWithdrawalRequestCommand
    export type PostApiWithdrawalCreateWithdrawalRequestMutationError = unknown

    export const usePostApiWithdrawalCreateWithdrawalRequest = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>, TError,{data: CreateWithdrawalRequestCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWithdrawalCreateWithdrawalRequest>>,
        TError,
        {data: CreateWithdrawalRequestCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWithdrawalCreateWithdrawalRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWithdrawalGetWithdrawalRequests = (
    getWithdrawalRequestsQuery: GetWithdrawalRequestsQuery,
 ) => {
      
      
      return apiClient<GetWithdrawalRequestsResponse>(
      {url: `/api/Withdrawal/getWithdrawalRequests`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getWithdrawalRequestsQuery
    },
      );
    }
  


export const getPostApiWithdrawalGetWithdrawalRequestsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>, TError,{data: GetWithdrawalRequestsQuery}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>, TError,{data: GetWithdrawalRequestsQuery}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>, {data: GetWithdrawalRequestsQuery}> = (props) => {
          const {data} = props ?? {};

          return  postApiWithdrawalGetWithdrawalRequests(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWithdrawalGetWithdrawalRequestsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>>
    export type PostApiWithdrawalGetWithdrawalRequestsMutationBody = GetWithdrawalRequestsQuery
    export type PostApiWithdrawalGetWithdrawalRequestsMutationError = unknown

    export const usePostApiWithdrawalGetWithdrawalRequests = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>, TError,{data: GetWithdrawalRequestsQuery}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWithdrawalGetWithdrawalRequests>>,
        TError,
        {data: GetWithdrawalRequestsQuery},
        TContext
      > => {

      const mutationOptions = getPostApiWithdrawalGetWithdrawalRequestsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWithdrawalApproveWithdrawalRequest = (
    approveWithdrawalRequestCommand: ApproveWithdrawalRequestCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Withdrawal/approveWithdrawalRequest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: approveWithdrawalRequestCommand
    },
      );
    }
  


export const getPostApiWithdrawalApproveWithdrawalRequestMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>, TError,{data: ApproveWithdrawalRequestCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>, TError,{data: ApproveWithdrawalRequestCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>, {data: ApproveWithdrawalRequestCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWithdrawalApproveWithdrawalRequest(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWithdrawalApproveWithdrawalRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>>
    export type PostApiWithdrawalApproveWithdrawalRequestMutationBody = ApproveWithdrawalRequestCommand
    export type PostApiWithdrawalApproveWithdrawalRequestMutationError = unknown

    export const usePostApiWithdrawalApproveWithdrawalRequest = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>, TError,{data: ApproveWithdrawalRequestCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWithdrawalApproveWithdrawalRequest>>,
        TError,
        {data: ApproveWithdrawalRequestCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWithdrawalApproveWithdrawalRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWithdrawalRejectWithdrawalRequest = (
    rejectWithdrawalRequestCommand: RejectWithdrawalRequestCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Withdrawal/rejectWithdrawalRequest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: rejectWithdrawalRequestCommand
    },
      );
    }
  


export const getPostApiWithdrawalRejectWithdrawalRequestMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>, TError,{data: RejectWithdrawalRequestCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>, TError,{data: RejectWithdrawalRequestCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>, {data: RejectWithdrawalRequestCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWithdrawalRejectWithdrawalRequest(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWithdrawalRejectWithdrawalRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>>
    export type PostApiWithdrawalRejectWithdrawalRequestMutationBody = RejectWithdrawalRequestCommand
    export type PostApiWithdrawalRejectWithdrawalRequestMutationError = unknown

    export const usePostApiWithdrawalRejectWithdrawalRequest = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>, TError,{data: RejectWithdrawalRequestCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWithdrawalRejectWithdrawalRequest>>,
        TError,
        {data: RejectWithdrawalRequestCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWithdrawalRejectWithdrawalRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiWithdrawalCancelWithdrawalRequest = (
    cancelWithdrawalRequestCommand: CancelWithdrawalRequestCommand,
 ) => {
      
      
      return apiClient<void>(
      {url: `/api/Withdrawal/cancelWithdrawalRequest`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cancelWithdrawalRequestCommand
    },
      );
    }
  


export const getPostApiWithdrawalCancelWithdrawalRequestMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>, TError,{data: CancelWithdrawalRequestCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>, TError,{data: CancelWithdrawalRequestCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>, {data: CancelWithdrawalRequestCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiWithdrawalCancelWithdrawalRequest(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiWithdrawalCancelWithdrawalRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>>
    export type PostApiWithdrawalCancelWithdrawalRequestMutationBody = CancelWithdrawalRequestCommand
    export type PostApiWithdrawalCancelWithdrawalRequestMutationError = unknown

    export const usePostApiWithdrawalCancelWithdrawalRequest = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>, TError,{data: CancelWithdrawalRequestCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiWithdrawalCancelWithdrawalRequest>>,
        TError,
        {data: CancelWithdrawalRequestCommand},
        TContext
      > => {

      const mutationOptions = getPostApiWithdrawalCancelWithdrawalRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
