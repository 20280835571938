import React, { useMemo } from "react";
import styled from "styled-components/macro";
import {
  AppTextBlack,
  FlexColumn,
  FlexRow,
} from "../../style/projectComponents";
import { useTranslation } from "react-i18next";
import toLocaleNumber from "../../utils/toLocaleNumber";
import { OrderItem } from "./OrderItem";
import { IOrderBookResponse, ITradeSide } from "../../service/bybit-api/models";
import { TabType } from "../../pages/MarketsPage/MarketsPage";

const Block = styled(FlexColumn)<{ $tab: TabType }>`
  width: 300px;
  justify-content: start;
  align-items: start;
  background: ${({ theme }) => theme.colors.gray050};
  border-radius: 20px;
  height: 100%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  img {
    width: 18px;
  }

  p {
    margin: 0;
    text-align: start;
    width: 100%;
  }

  @media (max-width: 600px) {
    display: ${(props) => (props.$tab === "orderbook" ? "block" : "none")};
  }
`;

const BlockHeader = styled(FlexColumn)`
  justify-content: start;
  width: 100%;
  padding: 10px 0;
`;

interface OrderBookPanelProps {
  lastPrice: number;
  orderBook: IOrderBookResponse | null;
  recentTradeSide: ITradeSide | null;
  setPriceValue: any;
  tab: TabType;
}

export const OrderBookPanel: React.FC<OrderBookPanelProps> = ({
  tab,
  lastPrice,
  orderBook,
  recentTradeSide,
  setPriceValue,
}) => {
  const { t } = useTranslation();

  const bids = orderBook && orderBook?.b;
  const bidsVolumes = bids?.map((bid) => Number(bid[1]));
  const highestBidVolume = bidsVolumes && Math.max(...bidsVolumes);
  const asks = orderBook && orderBook?.a;
  const asksReversed = useMemo(() => asks?.reverse(), [asks]);
  const asksVolumes = asks?.map((bid) => Number(bid[1]));
  const highestAskVolume = asksVolumes && Math.max(...asksVolumes);
  const isLastPriceGreen =
    recentTradeSide && recentTradeSide === ITradeSide.Buy;

  return (
    <Block $tab={tab}>
      <BlockHeader>
        <AppTextBlack style={{ marginLeft: 15, fontSize: 17 }}>
          {t("market.orderBook")}
        </AppTextBlack>
      </BlockHeader>
      {asksReversed &&
        asksReversed.length > 0 &&
        asksReversed.map((bid, idx) => (
          <OrderItem
            price={Number(bid[0])}
            amount={Number(bid[1])}
            total={Number(bid[0]) * Number(bid[1])}
            highestVolume={highestAskVolume}
            isBid
            key={idx}
            setPriceValue={setPriceValue}
          />
        ))}
      <FlexRow style={{ marginLeft: 15, padding: "5px 0" }}>
        <AppTextBlack
          style={{
            color: isLastPriceGreen
              ? `${({ theme }) => theme.colors.greenSuccess}`
              : `${({ theme }) => theme.colors.redFail}`,
          }}
        >
          {toLocaleNumber(lastPrice)}
        </AppTextBlack>
        {recentTradeSide && (
          <img
            src={`/images/buttons/arrow-bottom-${
              isLastPriceGreen ? "green" : "red"
            }.png`}
            style={{ transform: isLastPriceGreen && "rotate(180deg)" }}
            alt=""
          />
        )}
      </FlexRow>
      {bids &&
        bids.length > 0 &&
        bids.map((bid, idx) => (
          <OrderItem
            price={Number(bid[0])}
            amount={Number(bid[1])}
            total={Number(bid[0]) * Number(bid[1])}
            highestVolume={highestBidVolume}
            key={idx}
            setPriceValue={setPriceValue}
          />
        ))}
    </Block>
  );
};
