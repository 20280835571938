import styled from "styled-components/macro";
import { FlexColumn, Page } from "../../style/projectComponents";
import { OperationsComponent } from "../../components/DashboardComponents/OperationsComponent";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {
  IGetAllOperationsListAdminReq,
  IGetAllOperationsListAdminResp,
} from "../../service/admin-api/models";
import { DataEmptyComponent } from "../../components/global/DataEmptyComponent";
import { useTranslation } from "react-i18next";
import { IStatusOperation } from "../../service/user-api/models";
import { PaginationComponent } from "../../components/global/PaginationComponent";
import { generatePagesArray } from "../../utils/generatePagesArray";

const StyledBg = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.pageBg};
  width: 100vw;
  padding-top: 24px;
  height: 100%;
  margin-top: 30px;
  justify-content: flex-start;
`;

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  /* max-height: 740px; */
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const ChartTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const AdminOperations = () => {
  const [operationsData, setOperationsData] =
    useState<IGetAllOperationsListAdminResp | null>(null);

  const getOperationsItemsQuery = useMutation(
    (data: IGetAllOperationsListAdminReq) =>
      AdminApi.getAllOperationsList(data),
    {
      onSuccess: (data) => {
        setOperationsData({
          ...data,
          items: data.items.map((it, idx) => ({
            ...it,
            type: it.type,
          })),
        });
      },
    }
  );

  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const req: IGetAllOperationsListAdminReq = {
      statuses: Object.values(IStatusOperation),
      skip: page * itemsPerPage,
      take: itemsPerPage,
    };
    getOperationsItemsQuery.mutate(req);
  }, [page]);

  const totalPages = useMemo(
    () =>
      operationsData?.totalCount
        ? Math.ceil(operationsData?.totalCount / itemsPerPage)
        : 1,
    [operationsData?.totalCount, itemsPerPage]
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <StyledBg>
        <OperationsWrapper>
          <ChartTitle>{t("adminOperations.operationHistory")}</ChartTitle>
          {operationsData?.totalCount > 0 ? (
            <OperationsComponent
              keys={[
                "operationDate",
                "walletOwnerEmail",
                "balanceDiff",
                "type",
                "status",
              ]}
              operations={operationsData.items}
              withTopBorder
            />
          ) : (
            <DataEmptyComponent isLoading={getOperationsItemsQuery.isLoading} />
          )}
          {operationsData?.totalCount > 0 && (
            <PaginationComponent
              maxPages={totalPages}
              pageId={page}
              pagesNum={generatePagesArray(totalPages, page, 100)}
              setPageId={setPage}
            />
          )}
        </OperationsWrapper>
      </StyledBg>
    </Page>
  );
};
