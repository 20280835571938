import React, { useEffect, useState } from "react";
import { FlexColumn, FlexRow, Page } from "../../style/projectComponents";
import styled from "styled-components/macro";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import TradingViewChart from "../../components/global/TradingViewChart";
import { TradingPairsList } from "../../components/MarketsComponents/TradingPairsList";
import { MarketHeader } from "../../components/MarketsComponents/MarketHeader";
import { OrderBookPanel } from "../../components/MarketsComponents/OrderBookPanel";
import {
  IGetOrderBookReq,
  IGetRecentTradesResponse,
  IGetTickersReq,
  IGetTickersRes,
  ITickerCategory,
} from "../../service/bybit-api/models";
import { useQuery } from "react-query";
import BybitApi from "../../service/bybit-api/BybitApi";
import { TradingPanel } from "../../components/MarketsComponents/TradingPanel";
import { useForm } from "react-hook-form";

const StyledBg = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  width: calc(100vw - 24px);
  height: 100%;
  margin-top: 54px;
`;
const TradePanelWrapper = styled(FlexRow)`
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 600px;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.gray050};
  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TradingGraph = styled(FlexColumn)<{ $tab: TabType }>`
  width: calc(100% - 300px);
  height: 1000px;
  @media screen and (max-width: 600px) {
    display: ${(props) => (props.$tab === "chart" ? "block" : "none")};
    height: 700px;
    width: 100%;
  }
  @media screen and (max-width: 390px) {
    height: 550px;
    width: 100%;
  }
`;

const ChartTab = styled.div<{ $selected: boolean }>`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.black : theme.colors.gray};
  padding-bottom: 10px;
  border-bottom: ${({ theme, $selected }) =>
    $selected ? `solid 3px ${theme.colors.black}` : "solid 3px transparent"};
`;

const TabsToggle = styled(FlexRow)`
  display: none;
  @media screen and (max-width: 600px) {
    gap: 10px;
    margin-top: 20px;
    display: flex;
    width: 95%;
    justify-content: start;
    border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrayBorder};
  }
`;
export type TabType = "chart" | "orderbook" | "trading";

const MarketsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  const tradingPair = params.pair;

  const [orderBook, setOrderBook] = useState(null);
  const [tickers, setTickers] = useState<IGetTickersRes | null>(null);
  const [recentTrades, setRecentTrades] =
    useState<IGetRecentTradesResponse | null>(null);
  const tickersReq: IGetTickersReq = {
    category: ITickerCategory.spot,
  };
  const getMarketTickersQuery = useQuery(
    ["market_spot_tickers"],
    () => BybitApi.getTickers(tickersReq),
    {
      onSuccess: (data) => {
        setTickers(data);
      },
      refetchInterval: 1500,
    }
  );

  const recentTradesReq: IGetTickersReq = {
    category: ITickerCategory.spot,
    symbol: tradingPair,
  };
  const getMarketRecentTrades = useQuery(
    ["market_spot_recent_trades", tradingPair],
    () => BybitApi.getMarketRecentTrade(recentTradesReq),
    {
      onSuccess: (data) => {
        setRecentTrades(data);
      },
      refetchInterval: 1500,
    }
  );

  const oderBookReq: IGetOrderBookReq = {
    category: ITickerCategory.spot,
    limit: 20,
    symbol: tradingPair,
  };
  const getMarketOrderBook = useQuery(
    ["market_order_book", tradingPair],
    () => BybitApi.getOrderBook(oderBookReq),
    {
      onSuccess: (data) => {
        setOrderBook(data);
      },
      refetchInterval: 1500,
    }
  );

  const spotTickersList = tickers?.result?.list || null;
  const recentTradesList = recentTrades?.result?.list;
  const activePairTickers = spotTickersList?.find(
    (ticker) => ticker.symbol.toLowerCase() === tradingPair.toLowerCase()
  );
  const lastPrice = Number(activePairTickers?.lastPrice) || 0;
  const recentTrade = recentTradesList?.[0];
  const recentTradeSide = recentTrade?.side || null;
  const orderBookResult = orderBook?.result || null;

  // data for trading panel
  const {
    control,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      buyPrice: lastPrice.toString(),
      buyAmount: "",
      buyTotal: "",
      sellPrice: lastPrice.toString(),
      sellAmount: "",
      sellTotal: "",
    },
  });

  const [tab, setTab] = useState<TabType>("chart");

  return (
    <Page style={{ justifyContent: "start" }}>
      <StyledBg>
        <MarketHeader tickerList={spotTickersList} activePair={tradingPair} />
        <TabsToggle>
          <ChartTab
            $selected={tab === "chart"}
            onClick={() => {
              setTab("chart");
            }}
          >
            Chart
          </ChartTab>
          <ChartTab
            $selected={tab === "trading"}
            onClick={() => {
              setTab("trading");
            }}
          >
            Trading
          </ChartTab>
          <ChartTab
            $selected={tab === "orderbook"}
            onClick={() => {
              setTab("orderbook");
            }}
          >
            Order Book
          </ChartTab>
        </TabsToggle>
        <TradePanelWrapper>
          <TradingPairsList
            tab={tab}
            tradingPair={tradingPair}
            tickerList={spotTickersList}
          />
          <TradingGraph $tab={tab}>
            <ChartWrapper>
              <TradingViewChart pair={tradingPair} />
            </ChartWrapper>
            <TradingPanel
              lastPrice={lastPrice}
              tradingPair={tradingPair}
              control={control}
              setValue={setValue}
              errors={errors}
              watch={watch}
              handleSubmit={handleSubmit}
            />
          </TradingGraph>
          <OrderBookPanel
            tab={tab}
            lastPrice={lastPrice}
            recentTradeSide={recentTradeSide}
            orderBook={orderBookResult}
            setPriceValue={setValue}
          />
        </TradePanelWrapper>
      </StyledBg>
    </Page>
  );
};

export default observer(MarketsPage);
