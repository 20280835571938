import React, { ComponentPropsWithoutRef, useContext, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import {
  FlexRow,
  FlexRowBetween,
  StyledButton,
} from "../../style/projectComponents";
import { useTranslation } from "react-i18next";
import {
  IStatusOperation,
  IUserItemResponse,
} from "../../service/user-api/models";
import { format } from "date-fns";
import { ru, enGB } from "date-fns/locale";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import {
  IGetWithdrawalResponseItem,
  IWithdrawalStatus,
} from "../../service/withdrawal-api/models";
import toLocaleNumber from "../../utils/toLocaleNumber";
import EditCurrencyModal from "../AdminComponents/modal/EditCurrencyModal";
import { ICurrency, OperationsListAdmin } from "../../service/admin-api/models";
import AddUpdateNetworkModal from "../AdminComponents/modal/AddUpdateNetworkModal";
import DeleteWalletModal from "../AdminComponents/modal/DeleteWalletModal";
import EditWalletModal from "../AdminComponents/modal/EditWalletModal";

type OperationItemComponentProps = {
  keys: string[];
  operation:
    | any
    | ICurrency
    | IGetWithdrawalResponseItem
    | OperationsListAdmin
    | IUserItemResponse;
  openErrorMessageId: number;
  setOpenErrorMessageId: (e: any) => void;
  openErrorMessage: boolean;
  setOpenErrorMessage: (e: any) => void;
  isCreateCurrency?: boolean;
  isAdminWallets?: boolean;
  updateWalletMutate?: any;
  updateWalletMutateData?: any;
  walletsValidationRegex?: string;
};

const Block = styled(FlexRowBetween)`
  position: relative;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  align-items: center;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.gray050};
  /* width: calc(100% - 40px); */
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: auto;
    min-width: 100%;
    gap: 30px;
  }

  p {
    font-size: 12px;
    text-align: left;
    font-weight: 300;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  :hover {
    p {
      color: ${({ theme }) => theme.colors.blue};
      transition: all 0.3s ease;
    }
  }
`;

type DeclinedWrapperProps = {
  color: string;
  status: IStatusOperation | IWithdrawalStatus;
};

const DeclinedWrapper = styled(FlexRow)<DeclinedWrapperProps>`
  cursor: ${({ status }) =>
    status === IStatusOperation.rejected ? "pointer" : "pointer"};
  width: 150px;
  text-align: right;
  justify-content: flex-end;
  color: ${(props) => props.color};

  img {
    width: 1rem;
    height: 1rem;
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }
`;

const ErrorNotification = styled(FlexRow)<{ status?: IStatusOperation }>`
  position: absolute;
  right: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  top: 45px;
  z-index: 10;
  color: ${({ status, theme }) =>
    status === IStatusOperation.approved
      ? theme.colors.black
      : theme.colors.redFail};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.dark};

  img {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }

  p {
    color: ${(props) =>
      props.status === IStatusOperation.approved
        ? props.theme.colors.black
        : props.theme.colors.redFail};
  }
`;

const switchStatus = (status: IStatusOperation) => {
  switch (status) {
    case IStatusOperation.approved:
      return `${({ theme }) => theme.colors.black}`;
    case IStatusOperation.notApproved:
      return `${({ theme }) => theme.colors.dark}`;
    case IStatusOperation.rejected:
      return `${({ theme }) => theme.colors.redFail}`;
    case IStatusOperation.cancelled:
      return `${({ theme }) => theme.colors.redFail}`;
    case IStatusOperation.undefined:
      return "#6893DB";
  }
};
export const OperationItem: React.FC<
  ComponentPropsWithoutRef<"div"> & OperationItemComponentProps
> = (props) => {
  const {
    operation,
    openErrorMessageId,
    setOpenErrorMessageId,
    openErrorMessage,
    setOpenErrorMessage,
    keys,
    isCreateCurrency,
    isAdminWallets,
    onClick,
    updateWalletMutate,
    updateWalletMutateData,
    walletsValidationRegex,
    ...other
  } = props;

  const LanguageStore = useContext(LanguageStorage);
  const isRuLanguage = LanguageStore.language === "ru";
  const getDate = (date: string) =>
    format(new Date(date), "dd MMM yy. HH:mm", {
      locale: isRuLanguage ? ru : enGB,
    });
  const isDateBlock = (it) =>
    it.toLowerCase().includes("date") || it.toLowerCase().includes("createdon");
  const blockText = (it) =>
    (it.includes("evm") && (it ? "Yes" : "No")) ||
    (isDateBlock(it) && getDate(operation[it])) ||
    (it.toLowerCase().includes("type")
      ? t(`common.${operation[it]}`)
      : it.toLowerCase().includes("usd")
      ? `$ ${toLocaleNumber(operation[it] || 0, 0, 8)}`
      : (Number(operation[it]) === operation[it] &&
          toLocaleNumber(operation[it], 0, 8)) ||
        operation[it]);
  const getBlockWidth = (it) => (it.includes("evm") && 50) || 160;

  const { t } = useTranslation();

  const handleSwitch = () => {
    setOpenErrorMessage((prevState) => {
      return Number(other.id) === openErrorMessageId
        ? !prevState
        : openErrorMessage
        ? prevState
        : !prevState;
    });
    setOpenErrorMessageId(() => other.id);
  };
  const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  const handleCurrencyModal = () => {
    setOpenCurrencyModal((bool) => !bool);
  };

  const [openDeleteDepositAddressModal, setOpenDeleteDepositAddressModal] =
    useState(false);
  const handleDeleteDepositAddressModal = () => {
    setOpenDeleteDepositAddressModal((bool) => !bool);
  };

  const [activeAddress, setActiveAddress] = useState(null);
  const [activeWalletId, setActiveWalletId] = useState(null);
  const [openEditDepositAddressModal, setOpenEditDepositAddressModal] =
    useState(false);
  const handleEditDepositAddressModal = () => {
    setOpenEditDepositAddressModal((bool) => !bool);
  };

  const keysWithoutActions = keys.filter((k) => k !== "actions");
  const theme = useTheme();

  return (
    <Block {...other} style={{ cursor: !!onClick && "pointer" }}>
      {isCreateCurrency && (
        <EditCurrencyModal
          handleMenu={handleCurrencyModal}
          openMenu={openCurrencyModal}
          name={(operation as ICurrency)?.name}
          description={(operation as ICurrency)?.description}
          coinGeckoId={(operation as ICurrency)?.coinGeckoId}
          currencyId={(operation as ICurrency)?.id}
        />
      )}
      {isAdminWallets && (
        <DeleteWalletModal
          handleMenu={handleDeleteDepositAddressModal}
          openMenu={openDeleteDepositAddressModal}
          walletId={activeWalletId}
          mutate={updateWalletMutate}
          mutateData={updateWalletMutateData}
        />
      )}
      {isAdminWallets && (
        <EditWalletModal
          handleMenu={handleEditDepositAddressModal}
          openMenu={openEditDepositAddressModal}
          walletId={activeWalletId}
          walletAddress={activeAddress}
          mutate={updateWalletMutate}
          mutateData={updateWalletMutateData}
          walletsValidationRegex={walletsValidationRegex}
        />
      )}
      {openNetworkModal && (
        <AddUpdateNetworkModal
          handleMenu={() => setOpenNetworkModal((prev) => !prev)}
          openMenu={openNetworkModal}
          name={(operation as ICurrency)?.name}
          currencyId={(operation as ICurrency)?.id}
        />
      )}

      {keysWithoutActions
        .filter((it) => !it.toLowerCase().includes("status"))
        .map((it, idx) => (
          <p
            onClick={onClick}
            key={idx}
            style={{
              width: getBlockWidth(it),
              textAlign: idx === keys.length - 1 ? "right" : "left",
              fontWeight: 600,
              color:
                it === "balanceDiff" || it === "balanceDiffInUsd"
                  ? operation[it] >= 0
                    ? theme.colors.greenSuccess
                    : theme.colors.redFail
                  : it === "balance"
                  ? theme.colors.blue
                  : theme.colors.black,
            }}
          >
            {blockText(it)}
          </p>
        ))}
      {keysWithoutActions.find((it) => it === "status") && (
        <DeclinedWrapper
          color={switchStatus((operation as OperationsListAdmin).status)}
          status={(operation as OperationsListAdmin).status}
        >
          <p
            style={{
              marginRight: 5,
              fontWeight: 600,
            }}
          >
            {t(`common.${(operation as OperationsListAdmin).status}`)}
          </p>
          {(operation as OperationsListAdmin).comment?.length > 0 && (
            <img onClick={handleSwitch} src={"/images/info.svg"} alt="" />
          )}
        </DeclinedWrapper>
      )}

      {openErrorMessage && Number(other.id) === openErrorMessageId && (
        <ErrorNotification status={(operation as OperationsListAdmin).status}>
          <img src={"/images/info.svg"} alt={"info ico"} />
          <p>{(operation as OperationsListAdmin).comment}</p>
        </ErrorNotification>
      )}
      {isCreateCurrency && (
        <>
          <StyledButton
            onClick={handleCurrencyModal}
            variant="black"
            type="button"
          >
            Edit
          </StyledButton>
          <StyledButton
            onClick={() => setOpenNetworkModal(true)}
            variant="black"
            type="button"
          >
            Network
          </StyledButton>
        </>
      )}
      {isAdminWallets && (
        <FlexRow style={{ gap: 6 }}>
          <StyledButton
            onClick={() => {
              setActiveWalletId(operation?.id);
              setActiveAddress(operation?.address);
              handleEditDepositAddressModal();
            }}
            variant="black"
            type="button"
          >
            Edit
          </StyledButton>
          <StyledButton
            onClick={() => {
              setActiveWalletId(operation?.id);
              setOpenDeleteDepositAddressModal(true);
            }}
            variant="black"
            style={{ background: theme.colors.redFail, color: "#fff" }}
            type="button"
          >
            Delete
          </StyledButton>
        </FlexRow>
      )}
    </Block>
  );
};
