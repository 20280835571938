import Select from "react-select";

import styled from "styled-components/macro";

export type SelectLabelItem = {
  label: string;
  value: string;
};
export const StyledSelect = styled(Select)`
  width: 100%;
  font-size: 18px;

  & .Select__control {
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-radius: 16px;
    height: 60px;
    padding: 7px 7px 7px 20px;
    box-shadow: none;
    border-color: none !important;
    border: none;

    & :hover {
      box-shadow: none;
      border-color: none !important;
    }
  }

  & .Select__control--menu-is-open {
    box-shadow: none;
    border-color: none;

    & :hover {
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.blue};
      outline: none;
    }

    & .Select__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  & .Select__control--is-focused {
    box-shadow: none;
    border-color: none;

    & :hover {
      box-shadow: none;
      border-color: none;
    }
  }

  & .Select__menu {
    border-radius: 16px;
    border: none;
    padding-top: 5px;
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  & .Select__menu-list {
    max-height: 250px;
  }

  & .Select__value-container {
    text-align: left;
    color: ${({ theme }) => theme.colors.black};
    padding: 0;
  }

  & .Select__single-value {
    text-align: left;
    color: ${({ theme }) => theme.colors.black};
  }

  & .Select__indicators {
    width: 30px;
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__dropdown-indicator {
    background-image: url("/images/buttons/arrow-down.svg");
    background-repeat: no-repeat;
    width: 20px;
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};

    svg {
      display: none;
    }
  }

  & .Select__option {
    text-align: left;
    background: none;
  }

  & .Select__option:hover {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.black};
  }

  & .Select__option:active {
    background-color: ${({ theme }) => theme.colors.blue};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.blue};

    & :hover {
      background-color: ${({ theme }) => theme.colors.blue};
    }

    & :active {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;
