/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "styled-components/macro";
import { FlexColumn, StatsWrapper } from "../../style/projectComponents";
import { StatisticsComponent } from "../../components/DashboardComponents/StatisticsComponent";
import toLocaleNumber from "../../utils/toLocaleNumber";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import { GetFullStatisticAdmin } from "../../service/admin-api/models";
import { AdminAnalyticsBase } from "../../components/AdminComponents/AdminAnalytics/AdminAnalyticsBase";
import UserApi from "../../service/user-api/UserApi";

const Page = styled(FlexColumn)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pageBg};
`;

const StyledBg = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.pageBg};
  width: 100vw;
  height: auto;
  box-sizing: border-box;
  justify-content: flex-start;
  margin-top: 110px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }
`;

export const AdminAnalytics = () => {
  const { t } = useTranslation();
  const [dashboardData, setDashboardData] =
    useState<GetFullStatisticAdmin | null>(null);

  const getDashboardItemsQuery = useQuery(
    ["dashboard_items"],
    () => AdminApi.getFullStatistic(),
    {
      onSuccess: (data) => {
        setDashboardData(data);
      },
      retry: 1,
    }
  );

  const [usersPage, setUsersPage] = useState(0);
  const itemsPerPage = 20;

  const { data: usersData, isLoading: usersDataLoading } = useQuery(
    ["users_list", usersPage],
    () =>
      UserApi.getAllUsersList({
        skip: usersPage * itemsPerPage,
        take: itemsPerPage,
      })
  );

  const totalUsersPages = useMemo(
    () =>
      usersData?.totalCount
        ? Math.ceil(usersData?.totalCount / itemsPerPage)
        : 1,
    [usersData?.totalCount, itemsPerPage]
  );

  const totalBalance = dashboardData?.totalWalletsBalanceInUsd || 0;
  const currentInvestPoolsIncome =
    dashboardData?.currentAllInvestPoolsIncomeInUsd || 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <StyledBg>
        <StatsWrapper>
          <StatisticsComponent
            title={
              dashboardData?.usersStatistic?.totalUsersCount?.toString() || "0"
            }
            description={t("adminAnalytics.totalUsers")}
          />
          <StatisticsComponent
            title={`${toLocaleNumber(totalBalance)}`}
            symbol="USD"
            description={t("adminAnalytics.totalDeposits")}
          />
          <StatisticsComponent
            title={`${toLocaleNumber(currentInvestPoolsIncome)}`}
            symbol="USD"
            description={t("adminAnalytics.currentInvestPoolsIncome")}
          />
        </StatsWrapper>
        <AdminAnalyticsBase
          isDashboardLoading={getDashboardItemsQuery.isLoading}
          isUsersLoading={usersDataLoading}
          dashboardData={dashboardData}
          usersData={usersData}
          pageId={usersPage}
          setPageId={setUsersPage}
          totalUsersPages={totalUsersPages}
        />
      </StyledBg>
    </Page>
  );
};
