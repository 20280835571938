import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  AppTextNormal,
  AppTitleNormal,
  AssetsBlock,
  FlexColumn,
  MarketText,
  AssetsBlockHeader,
  Page,
} from "../style/projectComponents";
import { observer } from "mobx-react-lite";
import styled from "styled-components/macro";
import { AssetActionsItem } from "../components/AssetsComponents/AssetActionsItem";
import { useTranslation } from "react-i18next";
import toLocaleNumber from "../utils/toLocaleNumber";
import { useQuery } from "react-query";
import AdminApi from "../service/admin-api/AdminApi";
import { IGetCurrenciesRes } from "../service/admin-api/models";
import UserApi from "../service/user-api/UserApi";
import UserStorage from "../storage/UserStorage/UserStorage";
import ProfileStorage from "../storage/ProfileStorage/ProfileStorage";

const StyledBg = styled(FlexColumn)`
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  padding-bottom: 20px;
`;

const AssetsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const UserStore = useContext(UserStorage);
  const [profileData, setProfileData] = useState(null);
  const GetProfileQuery = useQuery(
    "profile_data",
    () => UserApi.getUserDetails(UserStore.user["UserId"]),
    {
      onSuccess: (data) => {
        setProfileData(data);
      },
      enabled: !!UserStore.accessToken,
    }
  );
  const wallets = profileData?.wallets;

  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);

  const [currencyList, setCurrencyList] = useState<IGetCurrenciesRes>();
  const totalBalance = ProfileStore?.profile?.totalBalanceInUsd || 0;
  const [userBalanceBtcValue, setUserBalanceBtcValue] = useState(0);
  const currencies = useMemo(
    () => currencyList?.currencies || [],
    [currencyList]
  );
  useEffect(() => {
    if (currencies.length) {
      const btcPrice =
        currencies?.find((c) => c.name.toLowerCase() === "btc")?.usdPrice || 0;
      setUserBalanceBtcValue(totalBalance / btcPrice);
    }
  }, [currencies]);

  useQuery(
    "currency_list",
    () =>
      AdminApi.getCurrencies({
        skip: 0,
        take: 50,
      }),
    {
      onSuccess: (data) => {
        setCurrencyList(data);
      },
    }
  );

  return (
    <Page style={{ justifyContent: "start" }}>
      <StyledBg>
        <AssetsBlockHeader
          style={{ flexDirection: "column", marginBottom: 32 }}
        >
          <AppTitleNormal style={{ marginBottom: 10 }}>
            {t("assets.totalAssets")}
          </AppTitleNormal>
          {totalBalance !== undefined && (
            <AppTextNormal>
              $ {toLocaleNumber(totalBalance)}(
              {userBalanceBtcValue ??
                userBalanceBtcValue?.toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })}{" "}
              BTC)
            </AppTextNormal>
          )}
        </AssetsBlockHeader>
        <AssetsBlock>
          <AssetsBlockHeader>
            <MarketText style={{ width: 150 }}>{t("assets.asset")}</MarketText>
            <MarketText style={{ width: 150 }}>
              {t("assets.balance")}
            </MarketText>
            <MarketText style={{ width: 175, textAlign: "right" }}>
              {t("assets.actions")}
            </MarketText>
          </AssetsBlockHeader>
          {currencies?.map((cur) => (
            <AssetActionsItem
              usdPrice={cur.usdPrice}
              title={cur.name}
              fullName={cur?.description}
              id={cur.id}
              key={cur.id}
              networks={cur.availableNetworksForDeposit}
              userBalance={
                wallets?.find(
                  (it) => it.currency.toLowerCase() === cur.name.toLowerCase()
                )?.balance || 0
              }
            />
          ))}
        </AssetsBlock>
      </StyledBg>
    </Page>
  );
};

export default observer(AssetsPage);
