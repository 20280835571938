import React, { useState } from "react";
import styled from "styled-components/macro";
import { StyledInput } from "../../style/projectComponents";

const DropDownComp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;

  @media (min-width: 700px) {
    width: 100%;
  }
`;
const DropDownMainWrapper = styled.div`
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 100%;
  background: ${({ theme }) => theme.colors.inputBackgroundSecondary};

  :active,
  :focus,
  :hover {
    border-color: black;
  }

  img {
    width: 16px;
    margin-left: 8px;
    filter: ${({ theme }) => theme.colors.black === "#fff" && "invert(1)"};
  }

  @media (min-width: 700px) {
    width: 100%;
  }
`;

interface DropdownContentProps {
  openMenu: boolean;
}

const DropdownContent = styled.div<DropdownContentProps>`
  margin-top: 20px;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border-radius: 5px;
  transform: translateY(calc(50% + 25px));
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  display: ${(props) => (props.openMenu ? "flex" : "none")};
  height: 160px;
  overflow: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    height: 30px;
    background-color: #a6a3a2;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 1);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface DropdownButtonProps {
  isActive: boolean;
}

const DropdownButton = styled.div<DropdownButtonProps>`
  color: ${(props) => (props.isActive ? "#fff" : props.theme.colors.black)};
  width: 100%;
  height: 22px;
  text-decoration: none;
  display: flex;
  align-items: start;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 20px;
  background: ${(props) =>
    props.isActive ? props.theme.colors.blue : props.theme.colors.white};
  cursor: pointer;

  p {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
  }

  img {
    width: 24px;
    border-radius: 100%;
  }

  &:hover {
    border: 1px solid #f2f1f0;
  }
`;
const DropdownText = styled.p`
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: start;

  @media (max-width: 768px) {
    //width: auto;
  }
`;

const SubtitleWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const Subtitle = styled.p`
  margin: 0;
  padding-bottom: 5px;
  color: gray;
  font-size: 15px;
`;

interface DropdownComponentProps {
  className?: string;
  itemId: number | null;
  itemList: any;
  setId: any;
  openMenu: any;
  setOpenMenu: any;
  hasSubTitle?: boolean;
  subtitle?: string;
  isInputHelper?: boolean;
  onClickItem?: any;
  placeholder?: string;
  activeItems?: number[];
}

export const DropdownComponent: React.FC<DropdownComponentProps> = ({
  className,
  placeholder,
  onClickItem = () => {},
  itemId,
  itemList,
  setId,
  isInputHelper = false,
  openMenu,
  setOpenMenu,
  hasSubTitle = false,
  subtitle = "",
  activeItems,
}) => {
  const [value, setValue] = useState(
    itemId < 0 || !itemId ? "" : itemList[itemId]
  );
  const [mapList, setMapList] = useState(itemList);
  const isActive = (id: number) => {
    if (activeItems) {
      return activeItems.some((el) => el === id);
    } else return id === itemId;
  };

  return (
    <DropDownComp className={className}>
      <DropDownMainWrapper onClick={() => setOpenMenu(!openMenu)}>
        {isInputHelper === false && (
          <>
            {hasSubTitle ? (
              <SubtitleWrapper>
                <Subtitle>{subtitle}</Subtitle>
                <DropdownText style={{ whiteSpace: "normal" }}>
                  {" "}
                  {itemList[itemId]}
                </DropdownText>
              </SubtitleWrapper>
            ) : (
              <DropdownText> {itemList[itemId]}</DropdownText>
            )}
          </>
        )}
        {isInputHelper && (
          <>
            <StyledInput
              style={{ width: "100%", backgroundColor: "white" }}
              onInput={() => setOpenMenu(true)}
              type={"text"}
              value={value}
              placeholder={placeholder || "Введите текст..."}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        )}
        <img
          style={{ rotate: openMenu ? "180deg" : "0deg" }}
          src="/images/buttons/arrow-down.svg"
          alt=""
        />
      </DropDownMainWrapper>
      <DropdownContent openMenu={openMenu}>
        {isInputHelper
          ? mapList
              .filter((it) => it.toLowerCase().includes(value.toLowerCase()))
              .map((x, idx) => (
                <DropdownButton
                  isActive={idx === itemId}
                  onClick={() => {
                    setId(itemList.findIndex((it) => it === x));
                    setOpenMenu(false);
                    setValue(x);
                    onClickItem();
                  }}
                  key={idx}
                >
                  <img src={x.img} alt="" />
                  <p style={{ textAlignLast: "start" }}>{x}</p>
                </DropdownButton>
              ))
          : itemList.map((x, idx) => (
              <DropdownButton
                isActive={isActive(idx)}
                onClick={() => {
                  setId(idx);
                  setOpenMenu(false);
                  setValue(x);
                  onClickItem();
                }}
                key={idx}
              >
                <img src={x.img} alt="" />
                <p style={{ textAlignLast: "start" }}>{x}</p>
              </DropdownButton>
            ))}
      </DropdownContent>
    </DropDownComp>
  );
};
