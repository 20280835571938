import React from "react";
import { TabsButton as TabsButtonDef } from "../../style/projectComponents";
import { TabsButton as TabsButtonTime } from "../../style/projectTimeComponents";
import { PROJECT_NAME } from "../../config/serverConstants";
import { OperationType } from "../../service/api/generated";
const TabsButton = PROJECT_NAME === "Time" ? TabsButtonTime : TabsButtonDef;

interface FilterMethodButtonProps {
  title?: string;
  imageLink?: string;
  isActive: boolean;
  setActiveTypeId: any;
  id: number;
  setTypes?: React.Dispatch<React.SetStateAction<OperationType[]>>;
  type?: null | OperationType;
}

export const FilterMethodButton: React.FC<FilterMethodButtonProps> = ({
  title,
  isActive,
  setActiveTypeId,
  id,
  imageLink,
  setTypes,
  type,
}) => {
  return (
    <TabsButton
      clicked={isActive}
      style={{
        fontSize: 14,
        background: !isActive && "none",
        color: `${({ theme }) => theme.colors.black}`,
      }}
      onClick={() => {
        setActiveTypeId(id);
        setTypes && setTypes(type ? [type] : []);
      }}
    >
      {title && <p>{title}</p>}
      {imageLink && <img src={imageLink} alt="" width={16} height={16} />}
    </TabsButton>
  );
};
