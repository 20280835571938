import styled from "styled-components/macro";
import { FlexColumn, Page } from "../../style/projectComponents";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import InvestPoolApi from "../../service/investpool-api/InvestPoolApi";
import { IGetAllInvestPoolsResponse } from "../../service/investpool-api/models";
import { InvestMethodsComponent } from "../../components/DashboardComponents/InvestMethodsComponent";
import { useGetApiInvestPoolGetAllInvestPools } from "../../service/api/generated";
import { PageLoader } from "../../components/global/PageLoader";

const StyledBg = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 30px;
  justify-content: flex-start;
`;

const MarketWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  margin-top: 10px;
`;
const LoaderWrapper = styled(FlexColumn)`
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;
export const AdminInvestPools = () => {
  const { data } = useGetApiInvestPoolGetAllInvestPools();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <StyledBg>
        <MarketWrapper>
          {data?.data?.items ? (
            <InvestMethodsComponent investPools={data?.data?.items} isAdmin />
          ) : (
            <LoaderWrapper>
              <PageLoader />
            </LoaderWrapper>
          )}
        </MarketWrapper>
      </StyledBg>
    </Page>
  );
};
