import React from "react";
import { Oval } from "react-loader-spinner";

export const PageLoader: React.FC = () => {
  return (
    <Oval
      height={50}
      width={50}
      //   color={`${({ theme }) => theme.colors.blue}`}
      //   secondaryColor={`${({ theme }) => theme.colors.primaryActive}`}
      color="#1f49bc"
      secondaryColor="#1f82bc"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};
