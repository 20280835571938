import React from "react";
import { HalfContent } from "../../../style/projectComponents";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { PROJECT_NAME } from "../../../config/serverConstants";

const LeftContent = styled(HalfContent)`
  color: #fff;
  background: ${PROJECT_NAME === "Def" ? "#000" : "#1A1B1E"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 70px;
  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
    max-width: 520px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;
const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 1600px) {
    transform: scale(1.2);
  }
  @media screen and (min-width: 1924px) {
    transform: scale(1.8);
  }
  @media screen and (min-width: 2440px) {
    transform: scale(2.3);
  }
  @media screen and (min-width: 3000px) {
    transform: scale(3.3);
  }
`;
const ImagePlanet = styled.img`
  position: absolute;
  object-fit: contain;
`;
const ImagePromo = styled.img`
  position: relative;
  top: -90px;
  left: -5px;
  margin-top: 32px;
  max-height: 400px;
`;
const LeftContentComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LeftContent>
      <TextContainer>
        <h2>{t("auth.presentation.title")}</h2>
        <p>{t("auth.presentation.description")}</p>{" "}
      </TextContainer>
      <ImagesContainer>
        {PROJECT_NAME === "Time" && (
          <ImagePlanet
            src="/images/Time/planet.png"
            alt="planet"
            width={"700px"}
          />
        )}
        <ImagePromo src="/images/promo/cmc-app.png" alt="promo" />
      </ImagesContainer>
    </LeftContent>
  );
};

export default LeftContentComponent;
