import { FieldValues } from "react-hook-form";
import { IFinalInput } from "./SecondStep";

export const isPhoneNumberGood = {
  required: {
    value: true,
    message: "auth.errors.phone.required",
  },
};

export const isEmailGood: FieldValues = {
  required: {
    value: true,
    message: "auth.errors.email.required",
  },
  pattern: {
    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
    message: "auth.errors.email.pattern",
  },
};

export const isPasswordGood: FieldValues = {
  required: {
    value: true,
    message: "auth.errors.password.required",
  },
  minLength: {
    value: 8,
    message: "auth.errors.password.minLength",
  },
  pattern: {
    value: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\/!@#$%^&]{8,}/g,
    message: "auth.errors.password.pattern",
  },
};
export const isPasswordAgainGood: FieldValues = (watch: any) => ({
  required: true,
  validate: (val: string) => {
    if (watch("password") != val) {
      return "auth.errors.password.validate";
    }
  },
});

export const finalInputs: IFinalInput[] = [
  {
    name: "email",
    label: "auth.email",
    type: "text",
    rules: isEmailGood,
  },
  {
    name: "receivedReferralCode",
    label: "auth.referralCode",
    type: "text",
    rules: { required: { value: false } },
  },
  {
    name: "password",
    label: "auth.password",
    type: "password",
    rules: isPasswordGood,
  },
  {
    name: "retypePassword",
    label: "auth.retype_password",
    type: "password",
    rules: isPasswordAgainGood,
  },
];
